import { useState } from 'react';
import { useInitForgotPassword } from '../api';
import ForgotPasswordCard from '../components/forgot_password/cards/ForgotPasswordCard';

export function ForgotPassword() {
  const [usedEmail, setUsedEmail] = useState('');
  const [initForgotPassword, { status, error, isLoading }] =
    useInitForgotPassword();

  return (
    <ForgotPasswordCard
      status={status}
      formProps={{
        onSubmit: (values) => {
          initForgotPassword({ userEmail: values.username });
          setUsedEmail(values.username);
        },
        externalError: error
          ? 'Something went wrong, please try again'
          : undefined,
        loading: isLoading,
      }}
      usedEmail={usedEmail}
    />
  );
}
