import { toast } from 'SRC/components/Toast';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIdentity } from 'SRC/hooks/useIdentity';
import {
  useConfirmSession,
  useCreateSession,
  useQueryForgotPasswordToken,
  useResetPassword,
} from '../api';
import ResetPasswordCard from '../components/forgot_password/cards/ResetPasswordCard';
import { LoginTwoFactorCard } from '../components/login_invite/cards/LoginTwoFactorCard';

export function ResetPassword() {
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Basics
  const { loggedIn, logOut, logIn, logTwoFactorIn, isTwoFactorProtected } =
    useIdentity();
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const hasLoggedOut = useRef(false);
  const mfaTries = useRef(0);
  const totp = useRef('');
  const [needsNewTwoFactor, setNeedsNewTwoFactor] = useState(false);

  const afterLogIn = () => {
    navigate({ pathname: '/' });
    toast.informative({ description: 'Your password has been changed' });
  };
  const unsuccessfullAutoLogin = () => {
    navigate({ pathname: '/login' });
    toast.informative({ description: 'Your password has been changed' });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Queries & Mutations
  const {
    data: dataToken,
    status: statusToken,
    error: errorToken,
  } = useQueryForgotPasswordToken(token!);

  const { mfaEnabledOnAccount, userEmail } = dataToken ?? {};

  // Reset password mutation
  const [resetPassword, { status: statusReset }] = useResetPassword({
    props: { token },
  });

  // MFA login
  const [confirmSession, { error: twoFactorError }] = useConfirmSession({
    onSuccess: async (response) => {
      logIn(response.accessToken, response.scopes);
      afterLogIn();
    },
    onError: () => {
      mfaTries.current = mfaTries.current + 1;
    },
    throwOnError: true,
  });

  // Base login
  const [createSession] = useCreateSession({
    async onSuccess(response) {
      if (isTwoFactorProtected(response.scopes)) {
        logTwoFactorIn(response.accessToken, response.scopes);

        // Let's try to use the MFA code given during password reset for login as well
        try {
          await confirmSession({
            totp: totp.current,
          });
        } catch (e) {
          // If it fails, promp for new MFA code
          setNeedsNewTwoFactor(true);
          return;
        }
      } else {
        logIn(response.accessToken, response.scopes);
        afterLogIn();
      }
    },
    onError: unsuccessfullAutoLogin,
  });

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Callbacks
  const onSubmit = async (values) => {
    const { password, mfaTotp } = values;

    totp.current = mfaTotp;

    await resetPassword({
      newPassword: password,
      mfaTotp,
    });
    await createSession({
      username: userEmail,
      password,
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Logout current user if any
  useEffect(() => {
    if (loggedIn && !hasLoggedOut.current) {
      hasLoggedOut.current = true;
      logOut();
    }
  });

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Render

  if (needsNewTwoFactor) {
    return (
      <LoginTwoFactorCard
        formProps={{
          onSubmit: confirmSession,
          externalError:
            !!twoFactorError && mfaTries.current !== 1
              ? 'Verification failed, your code may be invalid'
              : undefined,
        }}
        extraContent='Please provide a new code in order to login.'
      />
    );
  }

  if (statusToken === 'error') {
    if (
      errorToken?.httpCode === 400 ||
      errorToken?.primerErrorId === 'PasswordResetTokenExpired'
    )
      return <ResetPasswordCard status='requestExpired' />;

    if (
      errorToken?.httpCode === 410 ||
      errorToken?.primerErrorId === 'PasswordResetTokenUsed'
    )
      return <ResetPasswordCard status='requestAlreadyAccepted' />;

    return <ResetPasswordCard status='requestNotFound' />;
  }

  if (statusToken === 'success') {
    return (
      <ResetPasswordCard
        status='requestFound'
        formProps={{
          onSubmit,
          email: userEmail,
          mfaEnabled: mfaEnabledOnAccount,
          loading: statusReset === 'loading',
          externalError:
            statusReset === 'error'
              ? 'Something went wrong, please try again'
              : undefined,
        }}
      />
    );
  }

  return <ResetPasswordCard status='loadingRequest' />;
}
