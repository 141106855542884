export const PrimerIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height='32'
    viewBox='0 0 28 48'
    fill='black'
    {...props}
  >
    <path d='M25.5459 13.9322H17.882C15.7018 13.9322 13.9336 12.164 13.9336 9.98524V6.96681C13.9336 3.11862 10.815 0 6.96681 0C3.11862 0 0 3.11862 0 6.96681C0 10.815 3.11862 13.9336 6.96681 13.9336H9.98524C12.1655 13.9336 13.9322 15.7018 13.9322 17.882V25.0802C13.9322 27.2604 12.1655 29.0272 9.98524 29.0272H6.96681C3.11862 29.0272 0 32.1458 0 35.994V41.0332C0 44.8814 3.11862 48 6.96681 48C10.815 48 13.9336 44.8814 13.9336 41.0332V35.994V32.9756C13.9336 30.7954 15.7018 29.0272 17.882 29.0272H25.5459C26.8286 29.0272 27.8686 27.9872 27.8686 26.7045V16.2549C27.8672 14.9722 26.8286 13.9322 25.5459 13.9322Z' />
  </svg>
);
