import { FetchError } from 'SRC/fetch/fetch';
import * as Sentry from '@sentry/react';

export function sentryErrorCapture(callback?: (error: FetchError) => void) {
  return (error: FetchError) => {
    callback && callback(error);

    if (
      !error.status ||
      (error.status !== 403 && error.status !== 404 && error.status !== 401)
    ) {
      Sentry.captureException(error, {
        extra: {
          status: error.status,
          url: error.url,
          payload: JSON.stringify(error.payload),
        },
      });
    }
  };
}
