import { getHeaders } from 'SRC/fetch/headers';

export class FetchError extends Error {
  constructor(
    message: string,
    public status: number,
    public url: RequestInfo | URL,
    public payload: unknown,
  ) {
    super(message);
    this.name = this.constructor.name;
  }
}

type RequestConfig = {
  auth?: boolean;
};

export const get = <T>(
  url: RequestInfo,
  init?: Omit<RequestInit, 'method'>,
  config?: RequestConfig,
) => request<T>(url, init, config);

export const put = <T>(
  url: RequestInfo,
  init?: Omit<RequestInit, 'method'>,
  config?: RequestConfig,
) =>
  request<T>(
    url,
    {
      ...init,
      method: 'PUT',
    },
    config,
  );

export const post = <T>(
  url: RequestInfo,
  init?: Omit<RequestInit, 'method'>,
  config?: RequestConfig,
) =>
  request<T>(
    url,
    {
      ...init,
      method: 'POST',
    },
    config,
  );

export const del = <T>(
  url: RequestInfo,
  init?: Omit<RequestInit, 'method'>,
  config?: RequestConfig,
) => request<T>(url, { ...init, method: 'DELETE' }, config);

export const patch = <T>(
  url: RequestInfo,
  init?: Omit<RequestInit, 'method'>,
  config?: RequestConfig,
) =>
  request<T>(
    url,
    {
      ...init,
      method: 'PATCH',
    },
    config,
  );

async function request<T>(
  url: RequestInfo,
  init?: RequestInit,
  { auth = false }: RequestConfig = {},
): Promise<T> {
  const requestUrl = typeof url === 'string' ? url : url.url;

  const results = await fetch(url, {
    ...init,
    headers: getHeaders({
      auth,
      init,
      url: requestUrl,
    }),
  });

  /**
   * If we need to handle other content types, we can add them here or create new functions.
   */
  const contentType = results.headers.get('content-type');
  const isJson = contentType?.includes('application/json');
  const isBlob = contentType?.includes('application/octet-stream');
  const isText = contentType?.includes('text/');
  const isZip =
    contentType?.includes('application/zip') ||
    contentType?.includes('application/x-zip-compressed');

  const data = isJson
    ? await results.json()
    : isText
      ? await results.text()
      : isZip
        ? await results.blob()
        : isBlob
          ? await results.blob()
          : results;

  if (!results.ok) {
    //Legacy error messages
    const errorObject = isJson ? data?.error ?? data : null;
    const message = errorObject
      ? JSON.stringify(errorObject)
      : `${requestUrl} ${init?.method ?? 'GET'} failed, ${results.statusText}`;

    throw new FetchError(message, results.status, requestUrl, data);
  }

  return data;
}
