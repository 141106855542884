import { FormInput } from 'LEGACY/hooks/useForm';
import type { InputProps } from 'LEGACY/hooks/useForm/FormInput';

export const codeInputName = 'totp';

export const CodeInput = (
  props: Omit<InputProps, 'name'> & Partial<Pick<InputProps, 'name'>>,
) => (
  <div style={{ width: '100%' }}>
    <FormInput
      {...props}
      label='Authentication code'
      placeholder='123456'
      name={props.name ?? codeInputName}
      required='Code is required'
      validate={(val) =>
        val
          ? val.length === 6 || 'Code needs to have exactly 6 characters'
          : true
      }
      data-testid='input-code'
      autoComplete='off'
    />
  </div>
);
