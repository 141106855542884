import * as React from 'react';
import styled from 'styled-components';

import Theme from 'LEGACY/theme';
import Icon from 'LEGACY/components/Icon';
import { Space } from 'LEGACY/layout';

export type ButtonComponentProps = {
  onClick: (e?: React.MouseEvent) => void;
  disabled?: boolean;

  /**
   * @default 100%
   */
  width?: string;

  /**
   * @default 40px
   */
  height?: string;

  /**
   * @default Log in with Google
   */
  text?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = styled.button<ButtonComponentProps>`
  align-items: center;
  display: flex;
  justify-content: center;

  margin: 0;
  padding: 0 18px;

  background-color: ${Theme.colorLight};

  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height ?? '40px'};

  outline: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;

  font-family: ${Theme.fontFamily};
  font-size: 14px;
  font-weight: ${Theme.fontWeights.bold};
  cursor: pointer;
  white-space: nowrap;

  transition: box-shadow ${Theme.animationTransitionRegular};

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px #d9e6ff;
  }

  &:active {
    transform: translateY(1px);
    box-shadow: none;
    background-color: ${Theme.colorBackground};
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    box-shadow: none;
    cursor: default;

    color: ${Theme.colorLabelIco};
    background-color: ${Theme.colorBackground};
    border-color: ${Theme.colorPlaceholder};
  }
`;

const GoogleButton = React.forwardRef<HTMLButtonElement, ButtonComponentProps>(
  (props: ButtonComponentProps, ref) => {
    const {
      disabled = false,
      width,
      height,
      onClick,
      text = 'Log in with Google',
      ...otherProps
    } = props;

    return (
      <Button
        {...otherProps}
        disabled={disabled}
        type='button'
        width={width}
        height={height}
        onClick={onClick}
        ref={ref}
      >
        <Icon
          name={disabled ? 'Internal/GoogleDisabled' : 'Internal/Google'}
          size={16}
        />
        <Space of='row4' />
        {text}
      </Button>
    );
  },
);

GoogleButton.displayName = 'GoogleButton';
export default GoogleButton;
