import { ENVIRONMENT, getUrlEnvironment } from 'UTILS/env';
import { InfoCircle } from '@primer-io/goat-icons';
import { Icon, Link, P } from '@primer-io/goat';
import styles from './EnvStripe.module.scss';

interface Props {
  context: 'AUTH_LAYOUT' | 'APP_LAYOUT';
}

export function EnvStripe({ context }: Props) {
  const urlEnv = getUrlEnvironment();

  if (
    (context === 'AUTH_LAYOUT' &&
      (urlEnv === ENVIRONMENT.sandbox || urlEnv === ENVIRONMENT.production)) ||
    (context === 'APP_LAYOUT' && urlEnv === ENVIRONMENT.sandbox)
  ) {
    const envInfo =
      urlEnv === ENVIRONMENT.sandbox
        ? {
            currentName: 'sandbox',
            toName: 'production',
            toHref: 'https://dashboard.primer.io',
          }
        : {
            currentName: 'production',
            toName: 'sandbox',
            toHref: 'https://sandbox-dashboard.primer.io',
          };

    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <Icon as={InfoCircle} />
          <P>You are using the {envInfo.currentName} environment.</P>
          <Link href={envInfo.toHref} className={styles.link}>
            Switch to the {envInfo.toName} environment.
          </Link>
        </div>
      </div>
    );
  }
  return null;
}
