import { Button, color as goatColor, Icon, Topbar } from '@primer-io/goat';
import { EnvStripe } from 'SRC/components/EnvStripe/EnvStripe';
import { Color } from '@primer-io/goat-tokens';
import { CSSProperties, FC } from 'react';
import { useIdentity } from 'SRC/hooks/useIdentity';
import { LogOut } from '@primer-io/goat-icons';
import { Outlet } from 'react-router-dom';
import styles from './AuthLayout.module.scss';
import { PrimerWordmark } from 'SRC/components/AuthLayout/PrimerWordmark';

export const AuthLayout: FC<{ color?: Color }> = ({
  color = goatColor('gray-100'),
}) => {
  const { logOut, scopes, token } = useIdentity();

  return (
    <div
      style={{ '--color-bg-auth-layout-primary': color } as CSSProperties}
      className={styles.root}
    >
      <EnvStripe context='AUTH_LAYOUT' />
      <Topbar>
        <PrimerWordmark />
        {scopes && scopes.length && token ? (
          <Topbar.Actions>
            <Button onClick={logOut} variant='ghost'>
              <Icon as={LogOut} />
              Log out
            </Button>
          </Topbar.Actions>
        ) : null}
      </Topbar>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};
