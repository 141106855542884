import { useIdentity } from 'SRC/hooks/useIdentity';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export function LogOut() {
  const { logOut } = useIdentity();

  useEffect(() => {
    logOut();
    document.title = 'Dashboard | Primer';
  }, [logOut]);

  return <Navigate to='/login' replace={true} />;
}
