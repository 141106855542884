import { page as appsPagePermissions } from 'MODULES/apps/permissions';
import { page as checkoutPagePermissions } from 'MODULES/checkout/permissions';
import { page as developersPagePermissions } from 'MODULES/developers/permissions';
import { page as integrationsPagePermissions } from 'MODULES/integrations/permissions';
import { page as internalToolsPagePermissions } from 'MODULES/internalTools/permissions';
import { page as monitorsPagePermissions } from 'MODULES/monitors/permissions';
import { page as observabilityPagePermissions } from 'MODULES/observability/permissions';
import { page as paymentsPagePermissions } from 'MODULES/payments/permissions';
import { page as primerEtlPagePermissions } from 'MODULES/primerEtl/permissions';
import { page as reconciliationPagePermissions } from 'MODULES/reconciliation/permissions';
import { page as settingsPagePermissions } from 'MODULES/settings/permissions';
import { page as workflowsPagePermissions } from 'MODULES/workflows/permissions';

export const permissions = {
  apps: appsPagePermissions,
  checkout: checkoutPagePermissions,
  developers: developersPagePermissions,
  integrations: integrationsPagePermissions,
  internalTools: internalToolsPagePermissions,
  observability: observabilityPagePermissions,
  monitors: monitorsPagePermissions,
  primerEtl: primerEtlPagePermissions,
  payments: paymentsPagePermissions,
  reconciliation: reconciliationPagePermissions,
  settings: settingsPagePermissions,
  workflows: workflowsPagePermissions,
  changelogs: workflowsPagePermissions,
} as const;
