import styled from 'styled-components';
import Input from 'LEGACY/form/Input';
import { SupportedIconName } from './Icon';

const Root = styled.div``;

type OwnProps = Omit<JSX.IntrinsicElements['input'], 'height' | 'ref'> & {
  onSearch?: (value: string) => void;
  iconName?: SupportedIconName;
  styl?: 'regular' | 'large';
  onIconClick?: () => void;
};

export default function SearchInput(props: OwnProps) {
  const { placeholder, onSearch, style, ...otherProps } = props;

  const handleOnChange = (event) => {
    onSearch && onSearch(event.currentTarget.value);
  };
  return (
    <Root {...{ style }}>
      <Input
        {...otherProps}
        defaultValue={otherProps.defaultValue ?? ''}
        placeholder={placeholder ? placeholder : 'Search'}
        onChange={handleOnChange}
        rounded
        leftIconName='Search'
      />
    </Root>
  );
}
