import { ReactNode } from 'react';

export const errorStrings = {
  title: 'We have encountered an error',
  message: (linkNode: ReactNode) => (
    <>
      If the problem persists, please raise a ticket on our
      {linkNode}. If you don’t have access, please contact your account
      administrator for assistance.
    </>
  ),
  reloadButtonLabel: 'Try reloading the page',
};
