import { KeyFn } from 'LEGACY/api/common';
import { createMutation, createQuery } from 'LEGACY/api';
import { accountsApiUrl } from 'SRC/config';

////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Payload { userEmail: string }
 */
export const useInitForgotPassword = createMutation({
  api: {
    url: '/forgot-password',
    authenticate: false,
    apiBaseUrl: accountsApiUrl,
    method: 'POST',
    content: 'json',
    params: {
      onError: { skipAll: true },
      skipReloadOnUnauthorized: true,
      // skipTokenPurgingOnUnauthorized: true,
    },
  },
  mutation: {
    throwOnError: true,
  },
});

////////////////////////////////////////////////////////////////////////////////////////////////////

export const keyQueryForgotPasswordToken: KeyFn = ({ id }) =>
  `/forgot-password/${id}`;

/**
 * Returns { mfaEnabledOnAccount: boolean }
 */
export const useQueryForgotPasswordToken = createQuery<
  {
    mfaEnabledOnAccount: boolean;
    userEmail: string;
  },
  {
    httpCode: number;
    primerErrorId: string;
  }
>({
  key: keyQueryForgotPasswordToken,
  api: {
    url: ({ id }) => `/forgot-password/${id}`,
    apiBaseUrl: accountsApiUrl,
    method: 'GET',
    authenticate: false,
    // Has custom error handling based on context
    params: { onError: { skipAll: true }, skipReloadOnUnauthorized: true },
  },
});

////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Payload { newPassword: string, mfaTotp: string }
 */
export const useResetPassword = createMutation({
  api: {
    url: ({ $props }) => `/forgot-password/${$props.token}`,
    authenticate: false,
    apiBaseUrl: accountsApiUrl,
    method: 'POST',
    content: 'json',
    params: {
      onError: { skipAll: true },
      skipReloadOnUnauthorized: true,
      // skipTokenPurgingOnUnauthorized: true,
    },
  },
  mutation: {
    throwOnError: true,
  },
});
