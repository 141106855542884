import {
  QueryConfig as ReactQueryConfig,
  QueryResult,
  useQuery,
} from 'react-query';
import type { KeyFn } from './common';
import { mergeCreateApiRequestOptions } from './common';
import type { CreateApiRequestOptions } from './createApiRequest';
import createApiRequest from './createApiRequest';
import { isPlainObject } from 'UTILS/isPlainObject';

// https://react-query.tanstack.com/reference/useQuery
// as time goes we can add other options here
export interface QueryConfig<T, E> extends ReactQueryConfig<T, E> {
  /**
   * Can query be executed?
   */
  enabled?: boolean;

  /**
   * If set, any previous data will be kept when fetching new data because the query key changed.
   */
  keepPreviousData?: boolean;

  /**
   * Should query retry on error response?
   */
  retry?: boolean;

  /**
   * Callbacks
   */
  staleTime?: number;
  onSuccess?: (data: T) => void;
  onError?: (err: unknown) => void;
  onSettled?: (data: unknown, error: unknown) => void;
}

export const defaultQueryConfig: QueryConfig<unknown, unknown> = {
  retry: false,
  enabled: true,
};

/**
 * Merges query configs and makes sure callback are called as specified on each level
 */
function mergeQueryConfigs<T, E>(
  definitionQueryConfig?: QueryConfig<T, E>,
  usageQueryConfig?: QueryConfig<T, E>,
): QueryConfig<T, E> {
  const mergedQueryConfig = {
    ...(defaultQueryConfig as QueryConfig<T, E>),
    ...definitionQueryConfig,
    ...usageQueryConfig,
  };

  // Merge onError callback so later specified ones won't overwrite sooned defined ones
  mergedQueryConfig.onError = (e) => {
    defaultQueryConfig.onError?.(e);
    definitionQueryConfig?.onError?.(e);
    usageQueryConfig?.onError?.(e);
  };

  // Merge onSettled callback so later specified ones won't overwrite sooned defined ones
  mergedQueryConfig.onSettled = (data, e) => {
    defaultQueryConfig.onSettled?.(data, e);
    definitionQueryConfig?.onSettled?.(data, e);
    usageQueryConfig?.onSettled?.(data, e);
  };

  // Merge onSuccess callback so later specified ones won't overwrite sooned defined ones
  mergedQueryConfig.onSuccess = (data) => {
    defaultQueryConfig.onSuccess?.(data);
    definitionQueryConfig?.onSuccess?.(data);
    usageQueryConfig?.onSuccess?.(data);
  };

  return mergedQueryConfig;
}

type BuildQueryOptions<T, E> = {
  key: KeyFn | string;

  /**
   * You can pass defaults for query how to construct the API request, these usually are `url` and `method`
   */
  api: CreateApiRequestOptions;

  /**
   * You can pass defaults for actual query behaviour
   */
  query?: QueryConfig<T, E>;
};

type Id = string | number | Record<PropertyKey, unknown>;
type ApiOptionsOrQueryConfig<T, E> =
  | Partial<CreateApiRequestOptions>
  | QueryConfig<T, E>;
type IdOrApiOptionsOrQueryConfig<T, E> = Id | ApiOptionsOrQueryConfig<T, E>;

// Accepts 1-3 arguments, if 3 arguments - `id` must be first, otherwise order does not matter
// id: any,
// usageQueryConfig?: QueryConfig,
// usageCreateApiRequestOptions: CreateApiRequestOptions,
type CreateQueryArgs<T, E> =
  | []
  | [IdOrApiOptionsOrQueryConfig<T, E>]
  | [IdOrApiOptionsOrQueryConfig<T, E>, IdOrApiOptionsOrQueryConfig<T, E>]
  | [Id, ApiOptionsOrQueryConfig<T, E>, ApiOptionsOrQueryConfig<T, E>];

/** ************************************************************************************************
 * Main fn of the file
 **************************************************************************************************/
// TODO: remove `T = any` and type all queries
// TODO: change `E = any` to `E = unknown` and type all queries
export function createQuery<T = any, E = any>(
  buildOptions: BuildQueryOptions<T, E>,
): (...fnArgs: CreateQueryArgs<T, E>) => QueryResult<T, E> {
  const {
    key,
    api: definitionCreateApiRequestOptions,
    query: definitionQueryConfig,
  } = buildOptions;

  /*
   * CONSTRUCTING THE QUERY FN
   *
   * This created fn has dynamic signature of at most 3 arguments, two of which can be QueryConfig and CreateApiRequestOptions.
   * The extra parameter can be used to specify more details for the keyFn - it can be an actial id (string, number) but also an Object itself!
   *
   * Examples of valid usages:
   *
   * useQueryFn(3)
   * useQueryFn(3, { onSuccess: ... })
   * useQueryFn(3, { queryParams: { ... } })
   * useQueryFn(3, { onSuccess: ... }, { queryParams: { ... } })
   * useQueryFn(3, { queryParams: { ... } }, { onSuccess: ... })
   */
  const useQueryFn = (...fnArgs: CreateQueryArgs<T, E>): QueryResult<T, E> => {
    // PARSE ARGS
    const { id, usageQueryConfig, usageCreateApiRequestOptions } =
      processArgs(fnArgs);

    // MERGE QUERY CONFIG
    const mergedQueryConfig = mergeQueryConfigs(
      definitionQueryConfig,
      usageQueryConfig,
    );

    // MERGE API REQ OPTIONS
    const mergedCreateApiRequestOptions = mergeCreateApiRequestOptions(
      definitionCreateApiRequestOptions,
      usageCreateApiRequestOptions,
    );

    const queryKey =
      typeof key === 'string'
        ? key
        : key({
            // Making both `id` and `queryParams` available to key fn
            id,
            queryParams: mergedCreateApiRequestOptions.queryParams ?? undefined,
          });

    // For queries (as opposite to mutations), the fn created by `createApiRequest` is called immediately
    // when a query is called in code - you don't get the desctruct array as [mutation, response] so in order
    // to be able to pass the `id` to function created by `createApiRequest` we need to bind it below
    const apiRequest = createApiRequest(mergedCreateApiRequestOptions);

    return useQuery<T, E>(
      queryKey,
      apiRequest.bind(null, { id }),
      mergedQueryConfig,
    );
  };

  return useQueryFn;
}

const apiRequestProps = [
  'url',
  'method',
  'content',
  'queryParams',
  'authenticate',
  'format',
  'payload',
  'params',
];

const queryConfigProps = [
  'cacheTime',
  'enabled',
  'initialData',
  'initialStale',
  'isDataEqual',
  'keepPreviousData',
  'notifyOnStatusChange',
  'onError',
  'onSettled',
  'onSuccess',
  'queryFnParamsFilter',
  'queryKeySerializerFn',
  'refetchInterval',
  'refetchIntervalInBackground',
  'refetchOnMount',
  'refetchOnReconnect',
  'refetchOnWindowFocus',
  'retry',
  'retryDelay',
  'staleTime',
  'structuralSharing',
  'suspense',
  'useErrorBoundary',
];

/**
 * Detects single argument and its type
 */
function processArg<T, E>(
  arg: IdOrApiOptionsOrQueryConfig<T, E>,
):
  | { id: Id }
  | { usageCreateApiRequestOptions: Partial<CreateApiRequestOptions> }
  | { usageQueryConfig: QueryConfig<T, E> } {
  // If the arg is not an object, it can only be an `id`/`extra`
  if (!isPlainObject(arg) || typeof arg !== 'object')
    return {
      id: arg as string | number,
    };

  // These are options available to api request config, if some of them is present, the arg was meant as api request config
  if (apiRequestProps.some((key) => key in arg)) {
    return {
      usageCreateApiRequestOptions: arg as CreateApiRequestOptions,
    };
  }

  // These are options available to react query config, if some of them is present, the arg was meant as query config
  if (queryConfigProps.some((key) => key in arg)) {
    return {
      usageQueryConfig: arg as QueryConfig<T, E>,
    };
  }

  // The argument is an object, but does not match any config, treat it as an `id` but of object type
  return {
    id: arg as Record<PropertyKey, unknown>,
  };
}

/**
 * Detects all args passed to useQueryFn
 */
function processArgs<T, E>(
  args: CreateQueryArgs<T, E>,
): {
  id?: Id;
  usageCreateApiRequestOptions?: Partial<CreateApiRequestOptions>;
  usageQueryConfig?: QueryConfig<T, E>;
} {
  if (args.length === 0) return {};

  if (args.length === 1) {
    // Passed argument can be meant as either `usageQueryConfig` / `usageCreateApiRequestOptions` or some `id`/`extra`
    return processArg(args[0]);
  }

  if (args.length === 2) {
    // Any combination of the 2 from the 3 available arguments is possible - deduce
    return {
      ...processArg(args[0]),
      ...processArg(args[1]),
    };
  }

  if (args.length === 3) {
    // Used the full fn signature, mapping is straightforward
    // 1st param is `id` and allow for mixed order of query and api configs so deduce which one is which
    return {
      id: args[0],
      ...processArg(args[1]),
      ...processArg(args[2]),
    };
  }

  throw new Error(
    `Tried to use more than 3 arguments with useQueryFn created by createQuery`,
  );
}
