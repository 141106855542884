import Button from 'LEGACY/components/Button';
import Errors from 'LEGACY/form/Errors';
import Space from 'LEGACY/layout/Space';
import {
  CodeInput,
  codeInputName,
} from 'MODULES/auth/components/two_factor/CodeInput';
import useForm from 'LEGACY/hooks/useForm';

export type OwnProps = {
  onSubmit: (data: { totp: string }) => void;
  externalError?: string;
  loading?: boolean;
};

export function LoginTwoFactorForm({
  onSubmit,
  externalError,
  loading = false,
}: OwnProps) {
  const { renderForm, formErrors, formState } = useForm({ onSubmit });
  const { isSubmitting } = formState;

  return renderForm(
    <>
      <CodeInput
        omitRequiredLabel
        isSubmitting={loading || isSubmitting}
        styl='large'
        formItemProps={{ labelFwdProps: { styl: 'regular' } }}
        errors={formErrors && formErrors[codeInputName]}
      />
      <Space of='col32' />
      <Button
        type='submit'
        disabled={isSubmitting}
        loading={loading || isSubmitting}
        grow
        size='large'
        sentiment='branding'
        data-testid='button-submit'
        rightIcon='ArrowRight'
      >
        {loading || isSubmitting ? 'Verifying' : 'Verify'}
      </Button>
      {externalError && <Space of='col8' />}
      <Errors
        errorMessages={externalError ? [externalError] : undefined}
        data-testid='component-Errors'
      />
    </>,
  );
}
