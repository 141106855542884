/**
 * name: Jean-Baptiste Martin
 *
 * MODE: all → JBM
 * MODE: firstLast → JM
 */
export function nameToInitials(
  name: string,
  mode: 'all' | 'firstOnly' | 'firstLast' = 'firstLast',
) {
  if (mode === 'firstOnly') return name[0];

  const parts = name.replace(/-/, ' ').split(' ');

  if (mode === 'all') return parts.map((part) => part).join('');

  return `${parts[0][0]}${parts[parts.length - 1][0]}`;
}

export function namePartsToFullName(
  nameParts: { firstName?: string; lastName?: string },
  fallback = 'Unnamed',
): string {
  if (nameParts.firstName && nameParts.lastName)
    return `${nameParts.firstName} ${nameParts.lastName}`;

  return nameParts.firstName || nameParts.lastName || fallback;
}

/**
 * Reasoning:
 * http://www.regular-expressions.info/email.html
 */
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[A-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
