export const roles = {
  ADMIN: 'ADMIN',
  SUPPORT_AGENT: 'SUPPORT_AGENT',
  READONLY: 'READONLY',
} as const;

export const roleToText = {
  ADMIN: 'Admin',
  SUPPORT_AGENT: 'Support agent',
  READONLY: 'Read only',
};

export const roleDescriptions: Record<RoleId, string> = {
  ADMIN: 'An admin user has access to all features in the dashboard',
  READONLY:
    'A read-only user can view payments, as well as view the Home, Dashboards & Workflows.',
  SUPPORT_AGENT:
    'A support agent user can view and take actions on payments as well as view the Home, Dashboards & Workflows',
};

export type RoleId = keyof typeof roles;
