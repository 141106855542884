/**
 * Predicate for whether X has Y property
 * Source: https://fettblog.eu/typescript-hasownproperty/
 */
export function hasOwnProperty<
  X extends Record<PropertyKey, unknown>,
  Y extends PropertyKey,
>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  // eslint-disable-next-line no-prototype-builtins
  return obj.hasOwnProperty(prop);
}

export function hasControlledProps<ControlledProps, UncontrolledProps>(
  // Ignore due to wrong reporting of props being unused, it is used for type narrowing return
  // @ts-ignore
  props: ControlledProps | UncontrolledProps,
  propsValue: any,
): props is ControlledProps {
  return propsValue !== null && propsValue !== undefined;
}

export function hasControlledValue<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}
