import { merge as _merge } from 'lodash-es';
import type { CreateApiRequestOptions } from './createApiRequest';

/**
 * Merges createApiRequest options, which can be nested objects, hence using lodash's merge
 */
export function mergeCreateApiRequestOptions<T = Record<PropertyKey, any>>(
  definitionCreateApiRequestOptions?: CreateApiRequestOptions<T>,
  usageCreateApiRequestOptions?: Partial<CreateApiRequestOptions<T>>,
) {
  return _merge(
    {},
    definitionCreateApiRequestOptions,
    usageCreateApiRequestOptions,
  );
}

/**
 * You can use keyOptions.id / keyOptions.props / keyOptions.queryParams to generate key
 * Availability of those is based on how the query / mutation was build
 */
export type KeyFn = (keyOptions: {
  id?: string | number | Record<PropertyKey, unknown>;
  queryParams?: Record<PropertyKey, any>;
  props?: Record<PropertyKey, unknown>;
}) => string | Array<string | Record<PropertyKey, unknown>>;
