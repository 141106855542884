import {
  forwardRef,
  Fragment,
  Children,
  CSSProperties,
  ReactNode,
} from 'react';
import styled from 'styled-components';

type OwnProps = {
  children: ReactNode[];
  separator?: ReactNode;
  style?: CSSProperties;
  itemContainer?: (props: any) => JSX.Element;
  as?: any;
};

type OwnItemsProps = {
  items: ReactNode[];
  separator?: ReactNode;
};

const Root = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export default forwardRef<HTMLDivElement, OwnProps>(function List(props, ref) {
  const {
    children,
    separator,
    itemContainer: ItemContainer = Fragment,
    ...otherProps
  } = props;

  const childrenAdjusted = Children.toArray(children);

  const length = childrenAdjusted.length;
  return (
    <Root {...otherProps} ref={ref}>
      {childrenAdjusted.map((itm, index) => (
        <ItemContainer key={(itm as any).key}>
          {itm}
          {index + 1 !== length && separator}
        </ItemContainer>
      ))}
    </Root>
  );
});

export function ListItems(props: OwnItemsProps) {
  const length = props.items.length;
  return (
    <Root>
      {props.items.map((itm, index) => (
        <Fragment key={index}>
          {itm}
          {index + 1 !== length && props.separator}
        </Fragment>
      ))}
    </Root>
  );
}
