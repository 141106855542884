import Hotjar from '@hotjar/browser';
import {
  hotjarAllowTracking,
  hotjarDebug,
  hotjarId,
  hotjarSV,
} from 'SRC/config';
import { useIdentity } from 'SRC/hooks/useIdentity';
import { useEffect } from 'react';

type Section = 'PAYMENTS' | 'INTEGRATIONS' | 'WORKFLOWS';

type Action =
  | 'REQUEST_INTEGRATION'
  | 'SEARCH_PAYMENTS'
  | 'FILTER_PAYMENTS'
  | 'TIMELINE_EVENT_DETAIL_TAB_CHANGE'
  | 'TIMELINE_EVENT_DETAIL_OPEN';

export function initAnalytics() {
  if (hotjarAllowTracking) {
    Hotjar.init(+hotjarId, +hotjarSV, { debug: hotjarDebug });
  }
}

export function useUserAnalytics() {
  const { loggedIn, user, company } = useIdentity();

  useEffect(() => {
    if (!Hotjar.isReady()) return;

    Hotjar.identify(
      user?.id ?? null,
      loggedIn && user && company
        ? {
            // TODO partly obfuscate
            email: user.email,
            is_admin: user.accountRoles.includes('ADMIN'),
            // TODO partly obfuscate
            first_name: user.firstName,
            // TODO partly obfuscate
            last_name: user.lastName,
            company_id: company.id,
            company_name: company.companyName ?? '',
            mfa_enabled: user.mfaEnabled,
          }
        : {},
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);
}

export function trackUIEvent(section: Section, action: Action) {
  if (!Hotjar.isReady()) return;
  // The `undefined` below was containing the `Place`, which was never used
  Hotjar.event([section, undefined, action].join('|'));
}

export function suppressTrackingProps() {
  return {
    // Hotjar suppressing prop
    // https://help.hotjar.com/hc/en-us/articles/115012439167
    'data-hj-suppress': true,
  };
}
