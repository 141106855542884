import axios from 'axios';
import { clearIdentityFromStorage } from 'SRC/hooks/useIdentity';
import { toast } from 'SRC/components/Toast';
import { primerErrorIds } from './errorMessages';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please try again';
// feature flag bootstrap

export const client = axios.create({ withCredentials: false });
// ----- AUTH

// ----- CSRF One day in far future we will be able to add this
// export const nonMutableMethods = new Set(['GET', 'HEAD', 'OPTIONS']);
// export const getCsrfToken = () =>
//   // rather than parse all cookies onto an object indexed by key,
//   // we can scan with a regex for better performance
//   document.cookie.match(/csrf_token=([^;,]*)/)?.[1] ||
//   // unfortunately E2E tests can't set cookies on the same domain
//   // so we need an alternative way to read the CSRF token
//   (isLocal() && localStorage.getItem('csrf_token'));
//
// client.interceptors.request.use((request) => {
//   if (request.method && !nonMutableMethods.has(request.method.toUpperCase()))
//     request.headers['X-CSRF-TOKEN'] = getCsrfToken();
//
//   return request;
// });

client.interceptors.response.use(
  (response) => response,
  (error) => {
    // TODO "|| error" is a quickfix here, not sure if it ever worked with error.response
    const response = error.response || error || {};

    const params = response?.config?.__params ?? {};
    const payload = response?.config?.data ?? {};
    const queryParams = response?.config?.__queryParams ?? {};

    const errorObject = response?.data?.error ?? response?.data; //Fallback to support response from workflow api
    const primerErrorId = errorObject?.errorId ?? errorObject?.error_id;
    const primerDiagnosticsId =
      errorObject?.diagnosticsId ?? errorObject?.diagnostics_id;
    const primerDescription = errorObject?.description;
    const primerVersion = response?.headers?.['x-primer-version'];

    const isUnauthorized = response.status === 401;

    if (isUnauthorized) {
      if (!params.skipTokenPurgingOnUnauthorized) {
        clearIdentityFromStorage();
      }

      if (!params.skipReloadOnUnauthorized) {
        window.location.reload();
      }
    }

    let errorMessage;

    if (params.onError && !params.onError.skipAll) {
      if (params.onError.message) {
        errorMessage =
          typeof params.onError.message === 'string'
            ? params.onError.message
            : params.onError.message({
                primerErrorId,
                ...JSON.parse(payload ?? {}), // payload is a JSON string at this moment
                ...queryParams,
              });
      } else if (
        !params.onError.skipPrimerErrorId &&
        primerErrorIds[primerErrorId]
      ) {
        errorMessage = primerErrorIds[primerErrorId];
      } else if (!params.onError.skipDefault) {
        errorMessage = DEFAULT_ERROR_MESSAGE;
      }
    }
    if (!params.onError) {
      errorMessage = DEFAULT_ERROR_MESSAGE;
    }

    if (errorMessage) {
      toast.negative({ description: errorMessage });
    }

    // Augmenting the Error
    error.primerErrorId = primerErrorId;
    error.primerDiagnosticsId = primerDiagnosticsId;
    error.primerDescription = primerDescription;
    error.primerVersion = primerVersion;
    error.httpCode = parseInt(response.status, 10);
    error.httpCodeText = response.statusText;

    throw error;
  },
);
