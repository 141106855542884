import Theme from './theme';

export type Sentiment =
  | 'positive'
  | 'negative'
  | 'attentive'
  | 'important'
  | 'interactive'
  | 'neutral'
  // special cases
  | 'labelIco'
  | 'placeholder'
  | 'regular'
  | 'inverted'
  | 'branding'
  | 'none';

const sentimentColors = {
  positive: {
    fg: Theme.colorPositiveFg,
    bg: Theme.colorPositiveBg,
    mg: Theme.colorPositiveMg,
    ct: Theme.colorPositiveCt,
    buttonHover: Theme.colorPositiveBg,
    fgHover: Theme.colorPositiveMg,
  },
  negative: {
    fg: Theme.colorNegativeFg,
    bg: Theme.colorNegativeBg,
    mg: Theme.colorNegativeMg,
    ct: Theme.colorNegativeCt,
    buttonHover: Theme.colorNegativeBg,
    fgHover: Theme.colorNegativeMg,
  },
  attentive: {
    fg: Theme.colorAttentiveFg,
    bg: Theme.colorAttentiveBg,
    mg: Theme.colorAttentiveMg,
    ct: Theme.colorAttentiveCt,
    buttonHover: Theme.colorAttentiveBg,
    fgHover: Theme.colorAttentiveMg,
  },
  important: {
    fg: Theme.colorImportantFg,
    bg: Theme.colorImportantBg,
    mg: Theme.colorImportantMg,
    ct: Theme.colorImportantCt,
    buttonHover: Theme.colorImportantBg,
    fgHover: Theme.colorImportantMg,
  },
  interactive: {
    fg: Theme.colorInteraction,
    mg: Theme.colorInteraction,
    bg: Theme.colorLight,
    fgHover: Theme.colorInteractionHover,
    ct: Theme.colorInteractionCt,
    buttonHover: Theme.colorImportantBg, // This is not a typo
  },
  neutral: {
    fg: Theme.colorNeutralFg,
    bg: Theme.colorNeutralBg,
    mg: Theme.colorNeutralMg,
    ct: Theme.colorNeutralCt,
    buttonHover: Theme.colorNeutralBg,
    fgHover: Theme.colorNeutralMg,
  },
  // special cases
  labelIco: { fg: Theme.colorLabelIco, bg: Theme.colorLight },
  placeholder: { fg: Theme.colorPlaceholder, bg: Theme.colorLight },
  none: { fg: Theme.colorDark, bg: Theme.colorLight },
  regular: { fg: Theme.colorDark, bg: Theme.colorLight },
  inverted: { fg: Theme.colorLight, bg: Theme.colorDark, ct: Theme.colorLight },
  branding: {
    // branding sentiment - has all colors same so the coral can be accessed as sentiment regardless of foreground, background, ...
    fg: Theme.colorPrimerCoral,
    mg: Theme.colorPrimerCoral,
    bg: Theme.colorPrimerCoral,
    // specific colors
    ct: Theme.colorDark,
    buttonHover: Theme.colorPrimerCoralAtOpacity('0.35'),
  },
};

export function getSentimentColors(sentiment: Sentiment = 'neutral'): {
  fg: string;
  bg: string;
  mg?: string;
  ct?: string;
  fgHover?: string;
  bgHover?: string;
  buttonHover?: string;
} {
  return sentimentColors[sentiment];
}

export function mapResponseToSentiment(response: number) {
  if (response >= 500) return 'negative';
  if (response >= 400) return 'attentive';
  if (response >= 300) return 'important';
  if (response >= 200) return 'positive';
  return 'neutral';
}

// Currently used by DropdownPopout component. We have an animation that
// scales from a point of origin. This mapping function maps the available
// positioning props for Tippy to a CSS transform origin, which for the
// sake of the animation, needs to be the opposite side.
export function mapTippyPlacementToTransformOrigin(placement) {
  switch (placement) {
    case 'top':
      return 'bottom';
    case 'top-start':
      return 'bottom left';
    case 'top-end':
      return 'bottom right';
    case 'right':
      return 'left';
    case 'right-start':
      return 'top left';
    case 'right-end':
      return 'bottom left';
    case 'bottom':
      return 'top';
    case 'bottom-start':
      return 'top left';
    case 'bottom-end':
      return 'top right';
    case 'left':
      return 'right';
    case 'left-start':
      return 'top right';
    case 'left-end':
      return 'bottom right';
    default:
      return 'center';
  }
}
