import Button from 'LEGACY/components/Button';
import Errors from 'LEGACY/form/Errors';
import Helpers from 'LEGACY/form/Helpers';
import Space from 'LEGACY/layout/Space';
import { CodeInput } from 'MODULES/auth/components/two_factor/CodeInput';
import { standardPasswordRules } from 'MODULES/auth/const/password';
import { validatePassword } from 'MODULES/auth/utils';
import useForm, { FormInput, FormPassword } from 'LEGACY/hooks/useForm';

export type OwnProps = {
  onSubmit?: (values: unknown) => void | Promise<void>;
  email?: string;
  mfaEnabled?: boolean;
  externalError?: string;
  loading?: boolean;
};

export default function ResetPasswordForm(props: OwnProps) {
  const { onSubmit, email = '', mfaEnabled, externalError, loading } = props;

  const { renderForm, formErrors, formState, watchForm } = useForm({
    onSubmit,
    defaultValues: {
      email,
      password: '',
      mfaTotp: '',
    },
  });
  const { isSubmitting } = formState;
  const watchedPassword = watchForm('password');

  const passwordValidation = (password: string) =>
    validatePassword(email, password);

  return renderForm(
    <>
      <FormInput
        label='Email'
        name='email'
        disabled
        styl='large'
        autoComplete='username'
      />
      <Space of='col24' />
      <FormPassword
        name='password'
        label='New password'
        errors={formErrors.password}
        required
        helpers={standardPasswordRules}
        styl='large'
        autoComplete='new-password'
        minLength={8}
        validate={passwordValidation}
        visibleValue={watchedPassword}
      />
      {mfaEnabled && (
        <>
          <Space of='col24' />
          <CodeInput
            name='mfaTotp'
            isSubmitting={loading || isSubmitting}
            styl='large'
            errors={formErrors && formErrors.mfaTotp}
            extraContent={
              <Helpers
                helpMessages={[
                  'Open the authentication app on your device to view your code.',
                ]}
              />
            }
            iconName='Shield'
          />
        </>
      )}
      <Space of='col24' />
      <Button
        type='submit'
        disabled={isSubmitting}
        loading={isSubmitting}
        grow
        size='large'
        sentiment='branding'
        rightIcon='ArrowRight'
        data-testid='button-submit'
      >
        {loading || isSubmitting ? 'Changing' : 'Change password'}
      </Button>
      {externalError && (
        <>
          <Space of='col8' />
          <Errors
            errorMessages={[externalError]}
            data-testid='component-Errors'
          />
        </>
      )}
    </>,
  );
}
