import Button from 'LEGACY/components/Button';
import GoogleButton from 'LEGACY/components/social/GoogleButton';
import Errors from 'LEGACY/form/Errors';
import Space from 'LEGACY/layout/Space';
import { standardPasswordRules } from 'MODULES/auth/const/password';
import { validatePassword } from 'MODULES/auth/utils';
import useForm, { FormInput, FormPassword } from 'LEGACY/hooks/useForm';
import { OrSeparator } from '../../OrSeparator';

type Data = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

export type OwnProps = {
  onSubmit?: (data: Data) => void;
  email?: string;
  firstName?: string;
  lastName?: string;
  externalError?: {
    message: string;
    source: 'GOOGLE' | 'API';
  };
  onGoogleClick?: () => void;
};

export function NewUserForm({
  onSubmit,
  email = '',
  firstName = '',
  lastName = '',
  externalError,
  onGoogleClick,
}: OwnProps) {
  const { renderForm, formErrors, formState, watchForm } = useForm({
    onSubmit,
    defaultValues: {
      email,
      firstName,
      lastName,
      password: '',
    },
  });
  const { isSubmitting } = formState;
  const watchedPassword = watchForm('password');

  const passwordValidation = validatePassword.bind(null, email);

  return renderForm(
    <>
      <FormInput
        label='Email'
        name='email'
        disabled
        styl='large'
        autoComplete='username'
      />

      <Space of='col24' />

      <GoogleButton onClick={() => onGoogleClick?.()} />

      {externalError && externalError.source === 'GOOGLE' && (
        <>
          <Space of='col8' />
          <Errors
            errorMessages={[externalError.message]}
            data-testid='component-Errors'
          />
        </>
      )}

      <Space of='col24' />

      <OrSeparator />

      <Space of='col24' />

      <FormInput
        label='First name'
        name='firstName'
        errors={formErrors.firstName}
        required
        isSubmitting={isSubmitting}
        styl='large'
        autoComplete='given-name'
      />
      <Space of='col24' />
      <FormInput
        label='Last name'
        name='lastName'
        errors={formErrors.lastName}
        required
        isSubmitting={isSubmitting}
        styl='large'
        autoComplete='family-name'
      />
      <Space of='col24' />
      <FormPassword
        name='password'
        label='Password'
        errors={formErrors.password}
        required
        helpers={standardPasswordRules}
        styl='large'
        autoComplete='new-password'
        minLength={8}
        validate={passwordValidation}
        visibleValue={watchedPassword}
      />
      <Space of='col24' />
      <Button
        type='submit'
        disabled={isSubmitting}
        loading={isSubmitting}
        grow
        size='large'
        sentiment='branding'
        rightIcon='ArrowRight'
      >
        Create account
      </Button>
      {externalError && externalError.source === 'API' && (
        <>
          <Space of='col8' />
          <Errors
            errorMessages={[externalError.message]}
            data-testid='component-Errors'
          />
        </>
      )}
    </>,
  );
}
