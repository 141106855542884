import {
  useEffect,
  useState,
  useContext,
  useCallback,
  useLayoutEffect,
} from 'react';
import * as React from 'react';

export interface ModalContextItem {
  id: string;
  visible: boolean;
  zIndex: number;
  onDismiss?: () => void;
}

export interface ModalProviderState {
  activeModals: ModalContextItem[];
  addModal: (item: ModalContextItem) => void;
  removeModal: (item: ModalContextItem) => void;
}

interface ModalProviderProps {
  children: React.ReactNode;
}

export const defaultModalContext = (): ModalProviderState => {
  const modalContextErrorMessage = () => {
    throw new Error(
      'Please wrap the scene in the ModalProvider when using Modals',
    );
  };

  return {
    activeModals: [],
    addModal: modalContextErrorMessage,
    removeModal: modalContextErrorMessage,
  };
};

export const ModalContext = React.createContext<ModalProviderState>(
  defaultModalContext(),
);

export const ModalProvider = ({ children }: ModalProviderProps) => {
  // state is an array tracking the modals mounted on the page - visible or not
  const [state, setState] = useState<ModalContextItem[]>([]);

  const addModal = useCallback(
    (modal: ModalContextItem) => {
      setState((existingState: ModalContextItem[]) => {
        return [...existingState.filter((x) => x.id !== modal.id), modal];
      });
    },
    [setState],
  );

  const removeModal = useCallback(
    (modal: ModalContextItem) => {
      setState((existingState: ModalContextItem[]) => {
        return existingState.filter((x) => x.id !== modal.id);
      });
    },
    [setState],
  );

  useLayoutEffect(() => {
    // Handle body scroll lock
    const anyModalVisible = state.some((x) => x.visible);
    if (anyModalVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [state]);

  useEffect(() => {
    // Handle close on escape keypress
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        const highestModal = state
          .filter((x) => x.visible)
          .sort((a, b) => (a.zIndex < b.zIndex ? 1 : -1))[0];
        if (highestModal) {
          highestModal.onDismiss?.();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state]);

  const value: ModalProviderState = {
    activeModals: state,
    addModal,
    removeModal,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export function useModalContext() {
  return useContext<ModalProviderState>(ModalContext);
}
