import { PrimerErrorId } from 'LEGACY/api/errorMessages';
import { getTokenFromStorage } from 'SRC/hooks/useIdentity';
import { client } from 'LEGACY/api/client';
import {
  apiUrl,
  shouldAddXPrimerBranchHeader,
  xPrimerBranch,
} from 'SRC/config';

/*
 * ERROR HANDLING
 *
 * Each query / mutation can define its own error message that best describes the context of what went wrong and the consequences.
 * Example: login mutation can define error message as: `Could't log you in` rather than relying on PrimerErrorId that could just be EntityDoesntExists
 *
 * These are the steps in determining what is shown to user:
 *
 * 1. is `params.onError.message` defined? If yes, just use that and don't fallback
 * 2. fallback to matching primerErrorId unless `params.onError.skipPrimerErrorId` is set to true
 * 3. fallback to general error unless `params.onError.skipDefault` is set to true
 */

type MessageFunctionParams = {
  primerErrorId: PrimerErrorId;
} & Record<PropertyKey, unknown>;

type BehaveParams = {
  skipReloadOnUnauthorized?: boolean;
  skipTokenPurgingOnUnauthorized?: boolean;
  onError?: {
    /**
     * If defined, no other error in Toast will be shown
     */
    message?: string | ((args: MessageFunctionParams) => string);

    /**
     * Should the error handling fall all down to default message this allows to skip that as well
     * @default false
     */
    skipDefault?: boolean;

    /**
     * Should the error handling fall to mapping to PrimerErrorId this allows to skip that
     * @default false
     */
    skipPrimerErrorId?: boolean;

    /**
     * Skip showing any error message in toast - useful if the error is handled by some visual error state
     */
    skipAll?: boolean;
  };
};

type Obj = Record<PropertyKey, any>;

export type CreateApiRequestOptions<T = Record<PropertyKey, any>> = {
  url:
    | string
    | ((formattedFields: T, deprecatedNotUsedPathParams: Obj) => string);

  method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD' | 'OPTIONS';

  /**
   * @default json
   */
  content?: 'json' | 'form' | 'form-multipart';

  /**
   * Gets appended to `url` as query string
   */
  queryParams?: ((queryParams: T) => T) | Record<PropertyKey, any>;

  /**
   * Whether to include auth token in headers or not
   */
  authenticate?: boolean;

  /**
   * Fn to augment passed fields (1st param passed directly to query)
   */
  format?: (fields: T) => T;

  /**
   * Fn to augment final body of request
   * Form Data manipulation is done after this fn automatically
   */
  payload?: (formattedFields: T) => Obj;

  params?: BehaveParams;

  customHeaders?: Record<string, string>;

  /**
   * default is the defaultBaseUrl set with VITE_API_URL
   * can be overridden here to use a different base url
   */
  apiBaseUrl?: string | ((formattedFields: T) => string);
};

export type CreateApiRequestProduct = ReturnType<typeof createApiRequest>;
export default function createApiRequest<T = Record<PropertyKey, any>>({
  url,
  method,
  content = 'json',
  queryParams,
  authenticate = true,
  format = identity,
  payload = (fields) => fields as Record<PropertyKey, unknown>,
  params = {},
  customHeaders = {},
  apiBaseUrl = apiUrl,
}: CreateApiRequestOptions<T>) {
  return async function query(fields = {} as T, deprecatedNotUsedPathParams) {
    const headers: Record<string, unknown> = customHeaders;
    const formattedFields =
      typeof fields === 'object' ? format(fields) : ({} as T);
    const endpoint =
      typeof url === 'function'
        ? url(formattedFields, deprecatedNotUsedPathParams)
        : url;
    const queryObject =
      typeof queryParams === 'function'
        ? queryParams(formattedFields)
        : queryParams;
    const token = getTokenFromStorage();
    const baseUrl =
      typeof apiBaseUrl === 'function'
        ? apiBaseUrl(formattedFields)
        : apiBaseUrl;

    if (authenticate && token) {
      headers.Authorization = `Bearer ${token}`;
    }

    if (shouldAddXPrimerBranchHeader(`${baseUrl}${endpoint}`)) {
      headers['x-primer-branch'] = xPrimerBranch;
    }

    switch (content) {
      case 'json':
        headers['Content-Type'] = 'application/json';
        break;

      case 'form':
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
        break;

      case 'form-multipart':
        headers['Content-Type'] = 'multipart/form-data';
        break;

      default:
        break;
    }

    const options: Record<PropertyKey, unknown> = {
      url: `${baseUrl}${endpoint}`,
      headers,
      method,
    };

    if (method !== 'GET') {
      const data =
        typeof payload === 'function'
          ? payload(formattedFields)
          : formattedFields;
      options.data = content.startsWith('form')
        ? toFormData(data as Record<PropertyKey, string>)
        : data;
    }

    if (queryObject) {
      options.url += toQueryString(queryObject);
    }

    options.__params = params;
    options.__queryParams = queryObject ?? {};

    const response = await client(options);

    /**
     * Quick fix to go around dynamodb cursor-based pagination.
     */
    if (
      Array.isArray(response.data?.data) &&
      !('prevCursor' in response.data || 'next_page_token' in response.data)
    ) {
      return response.data.data;
    }
    return response.data;
  };
}

function toQueryString(fields): string {
  const entries = Object.entries(fields);

  if (entries.length === 0) {
    return '';
  }

  const params = entries
    .filter(([, v]) => v != null)
    .map(
      ([key, val]) =>
        `${key}=${encodeURIComponent(val as string | number | boolean)}`,
    );

  return '?' + params.join('&');
}

function toFormData(fields: Record<PropertyKey, string>): FormData {
  const formData = new FormData();
  Object.entries(fields).forEach((entry) => formData.append(...entry));
  return formData;
}

function identity<T>(val: T): T {
  return val;
}
