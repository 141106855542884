export function getLocalStorage(): Storage {
  if (localStorage) {
    return localStorage;
  }
  return {
    getItem: (_key: string) => null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setItem: (_key: string, _value: string) => {},
    key(_index: number) {
      return null;
    },
    length: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    removeItem: (_key: string) => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clear: () => {},
  };
}
