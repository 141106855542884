import { forwardRef, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import Theme from 'LEGACY/theme';
import Icon from 'LEGACY/components/Icon';
import type { SupportedIconName } from 'LEGACY/components/Icon';

export type OwnProps = Omit<JSX.IntrinsicElements['input'], 'ref'> & {
  /**
   * @default text
   */
  type?: string;

  hasErrors?: boolean;

  /**
   * @default regular
   */
  styl?: 'regular' | 'large';

  isNaked?: boolean;
  isWrapped?: boolean;

  /**
   * @define false
   */
  rounded?: boolean | 'as-select';

  placeholderColor?: string;

  // TODO rename to rightIconName
  iconName?: SupportedIconName;
  onIconClick?: () => void;

  leftIconName?: SupportedIconName;
  onLeftIconClick?: () => void;

  groupPosition?:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'middle-row'
    | 'middle-col';

  as?: 'textarea' | 'input';
  height?: string;
};

const iconSizeRegular = 20;
const iconIndentRegular = (rounded = false) => (rounded ? 10 : 5);
const iconIndentLarge = (rounded = false) => (rounded ? 14 : 11);

function calculateSidePadding(
  side: 'left' | 'right',
  props: OwnProps & { rightIcon?: boolean; leftIcon?: boolean },
): string {
  let paddingAsNumber = 0;

  const iconSize = iconSizeRegular;
  const iconIndent =
    props.styl === 'large'
      ? iconIndentLarge(!!props.rounded)
      : iconIndentRegular(!!props.rounded);

  if (
    props.rounded &&
    ((!props.rightIcon && side === 'right') ||
      (!props.leftIcon && side === 'left'))
  ) {
    paddingAsNumber += 10;
  }

  if (
    (props.rightIcon && side === 'right') ||
    (props.leftIcon && side === 'left')
  ) {
    paddingAsNumber += iconSize + 1.5 * iconIndent;
  }

  if (paddingAsNumber === 0) return '';

  return `padding-${side}: ${paddingAsNumber}px;`;
}

const InputRaw = styled.input<
  OwnProps & {
    borderColor?: string;
    roundedCornersSize?: string;
    $height?: string;
    borderColorInteraction?: string;
    boxShadowInteraction?: string;
    isCustomPassword?: boolean;
    isCustomPasswordVisibility?: string;
    leftIcon?: boolean;
    rightIcon?: boolean;
  }
>`
  display: block;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid
    ${(props) => (props.isNaked ? 'transparent' : props.borderColor)};
  margin: 0;
  padding: ${(props) =>
    props.styl === 'large'
      ? `${Theme.paddingColumn8} ${Theme.paddingRow8};`
      : `${Theme.paddingColumn4} ${Theme.paddingRow4};`};
  ${(props) =>
    props.isNaked &&
    (props.styl === 'large'
      ? `padding: calc(${Theme.paddingColumn8} - 1px) calc(${Theme.paddingRow8} - 1px);`
      : `padding: calc(${Theme.paddingColumn4} - 1px) calc(${Theme.paddingRow4} - 1px);`)};

  ${(props) => calculateSidePadding('left', props)}
  ${(props) => calculateSidePadding('right', props)}

  border-radius: ${(props) => {
    if (!props.groupPosition) return props.roundedCornersSize;
    if (
      props.groupPosition === 'middle-row' ||
      props.groupPosition === 'middle-col'
    )
      return `0px`;
    if (props.groupPosition === 'left')
      return `${props.roundedCornersSize} 0px 0px ${props.roundedCornersSize}`;
    if (props.groupPosition === 'right')
      return `0px ${props.roundedCornersSize} ${props.roundedCornersSize} 0px`;
    if (props.groupPosition === 'top')
      return `${props.roundedCornersSize} ${props.roundedCornersSize} 0px 0px`;
    if (props.groupPosition === 'bottom')
      return `0px 0px ${props.roundedCornersSize} ${props.roundedCornersSize}`;
  }};

  background: ${Theme.colorLight};

  color: ${Theme.colorDark};
  caret-color: ${Theme.colorDark};
  ${(props) =>
    props.$height
      ? `height: ${props.$height};`
      : `height: ${props.styl === 'large' ? '42px' : '30px'};`}

  ${(props) =>
    props.isNaked && `height: ${props.styl === 'large' ? '40px' : '28px'};`}
  font-family: ${Theme.fontFamily};
  font-size: ${(props) => (props.styl === 'large' ? '16px' : '14px')};
  line-height: ${(props) => (props.styl === 'large' ? '24px' : '22px')};
  font-weight: 400;

  outline: none;
  overflow: auto;
  resize: none;

  &:hover {
    border-color: ${(props) =>
      props.isNaked ? 'transparent' : props.borderColorInteraction};
    ${(props) => props.groupPosition && 'z-index: 100;'}
  }

  &:focus {
    border-color: ${(props) =>
      props.isNaked ? 'transparent' : props.borderColorInteraction};
    box-shadow: ${(props) =>
      props.isNaked ? 'none' : props.boxShadowInteraction};
    ${(props) => props.groupPosition && 'z-index: 100;'}
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    cursor: not-allowed;
    color: ${Theme.colorNeutralFg};
    background: #f2f2f2;
    border-color: ${(props) =>
      props.isNaked ? 'transparent' : Theme.colorPlaceholder};
    box-shadow: none;
  }

  &:read-only,
  &:read-only:hover,
  &:read-only:focus {
    cursor: not-allowed;
    color: ${Theme.colorNeutralFg};
    border-color: ${(props) =>
      props.isNaked ? 'transparent' : Theme.colorBorder};
    box-shadow: none;
  }

  &::placeholder {
    color: ${(props) => props.placeholderColor ?? '#c0c0c0'};
  }

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  ${(props) =>
    props.isCustomPassword &&
    `
    font-family: ${Theme.fontFamilyCode};
    ${
      props.isCustomPasswordVisibility === 'visible' &&
      `color: ${Theme.colorLight} !important;`
    }
  `}
`;

export function getGroupPositionCSS(
  props: Omit<OwnProps, 'as' | 'name'> & {
    isHovered?: boolean;
    isFocused?: boolean;
  },
): string {
  const parts: string[] = [];

  if (props.groupPosition || !props.isWrapped) {
    if (['middle-row', 'right'].includes(props.groupPosition!))
      parts.push(`margin: 0px 0px 0px -1px;`);
    if (['middle-col', 'bottom'].includes(props.groupPosition!))
      parts.push(`margin: -1px 0px 0px 0px;`);
  }

  if (props.groupPosition) parts.push(`flex-grow: 1;`);
  if (props.groupPosition) parts.push(`z-index: 1;`);
  if (props.groupPosition && (props.isHovered || props.isFocused))
    parts.push(`z-index: 100;`);

  return parts.join('') ?? '';
}

const InputWrapper = styled.div<
  Omit<OwnProps, 'as' | 'name'> & {
    rightIcon?: boolean;
    leftIcon?: boolean;
    isFocused?: boolean;
    isHovered?: boolean;
  }
>`
  display: block;
  width: 100%;

  ${(props) =>
    (props.groupPosition || props.rightIcon || props.leftIcon) &&
    'position: relative;'}
  ${(props) => getGroupPositionCSS(props)}
`;

const Input = forwardRef((props: OwnProps, ref) => {
  const {
    type = 'text',
    hasErrors,
    styl = 'regular',
    iconName,
    onIconClick,
    leftIconName,
    onLeftIconClick,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    isWrapped = false,
    rounded = false,
    as = 'input',
    height,
    ...fwdProps
  } = props;

  const roundedCornersSize =
    rounded === 'as-select'
      ? Theme.roundedCornersRegular
      : rounded
        ? Theme.roundedCornersPill
        : Theme.roundedCornersSmall;

  const rawInputRef = useRef<HTMLDivElement>(null);

  const [isFocused, setIsFocused] = useState(fwdProps.autoFocus ?? false);
  const [isHovered, setIsHovered] = useState(false);

  const onFocusAdjusted = useCallback(
    (evt) => {
      setIsFocused(true);
      onFocus && onFocus(evt);
    },
    [onFocus, setIsFocused],
  );
  const onBlurAdjusted = useCallback(
    (evt) => {
      setIsFocused(false);
      onBlur && onBlur(evt);
    },
    [onBlur, setIsFocused],
  );
  const onMouseEnterAdjusted = useCallback(
    (evt) => {
      setIsHovered(true);
      onMouseEnter && onMouseEnter(evt);
    },
    [onMouseEnter, setIsHovered],
  );
  const onMouseLeaveAdjusted = useCallback(
    (evt) => {
      setIsHovered(false);
      onMouseLeave && onMouseLeave(evt);
    },
    [onMouseLeave, setIsHovered],
  );

  const sentimentProps = {
    normal: {
      borderColor: Theme.colorPlaceholder,
      borderColorInteraction: Theme.colorInteraction,
      boxShadowInteraction: Theme.shadowInteraction,
    },
    error: {
      borderColor: Theme.colorNegativeFg,
      borderColorInteraction: Theme.colorNegativeFg,
      boxShadowInteraction: Theme.shadowNegative,
    },
  }[hasErrors ? 'error' : 'normal'];

  const rightIconComponent = !iconName ? null : (
    <Icon
      name={iconName}
      size={iconSizeRegular}
      style={{
        position: 'absolute',
        right:
          styl === 'regular'
            ? `${iconIndentRegular(!!rounded)}px`
            : `${iconIndentLarge(!!rounded)}px`,
        top:
          styl === 'regular'
            ? `${iconIndentRegular()}px`
            : `${iconIndentLarge()}px`,
        cursor: props.disabled || props.readOnly ? 'default' : 'pointer',
      }}
      onClick={() => {
        if (onIconClick) {
          onIconClick();
        }

        if (rawInputRef.current) rawInputRef.current.focus();
      }}
      sentiment='placeholder'
    />
  );

  const leftIconComponent = !leftIconName ? null : (
    <Icon
      name={leftIconName}
      size={iconSizeRegular}
      style={{
        position: 'absolute',
        left:
          styl === 'regular'
            ? `${iconIndentRegular(!!rounded)}px`
            : `${iconIndentLarge(!!rounded)}px`,
        top:
          styl === 'regular'
            ? `${iconIndentRegular()}px`
            : `${iconIndentLarge()}px`,
        cursor: props.disabled || props.readOnly ? 'default' : 'pointer',
      }}
      onClick={() => {
        if (onLeftIconClick) {
          onLeftIconClick();
        }

        if (rawInputRef.current) rawInputRef.current.focus();
      }}
      sentiment='placeholder'
    />
  );

  return (
    <InputWrapper
      groupPosition={props.groupPosition}
      isFocused={isFocused}
      isHovered={isHovered}
      isWrapped={isWrapped}
      leftIcon={!!leftIconComponent}
      rightIcon={!!rightIconComponent}
    >
      {leftIconComponent}
      <InputRaw
        as={as}
        $height={height}
        rounded={rounded}
        roundedCornersSize={roundedCornersSize}
        leftIcon={!!leftIconComponent}
        rightIcon={!!rightIconComponent}
        type={type}
        styl={styl}
        {...fwdProps}
        {...sentimentProps}
        ref={(_ref) => {
          if (ref) {
            if (typeof ref === 'function') {
              ref(_ref);
            } else {
              ref.current = _ref;
            }
          }
          (rawInputRef.current as any) = _ref;
        }}
        onFocus={onFocusAdjusted}
        onBlur={onBlurAdjusted}
        onMouseEnter={onMouseEnterAdjusted}
        onMouseLeave={onMouseLeaveAdjusted}
      />
      {rightIconComponent}
    </InputWrapper>
  );
});

export default Input;
