export const appEnv = import.meta.env.VITE_ENV || 'local';

export const canOverrideEnvVariables = ['local', 'dev', 'staging'].includes(
  appEnv,
);

export const isTesting = import.meta.env.VITEST;

export const API_ENV_VARS = [
  'API url',
  'Workflows API URL',
  'Accounts API URL',
  'ETL API URL',
  'Payment integration API URL',
  'Payment timeline API URL',
  'Nango API URL',
  'Assets URL',
  'Goat CDN URL',
] as const;
export const OVERRIDABLE_ENV_VARS = [
  'x-primer-branch Header',
  ...API_ENV_VARS,
] as const;

export const getOverrideableEnvVariable = (
  key: (typeof OVERRIDABLE_ENV_VARS)[number],
  defaultValue: string,
) => {
  if (isTesting) return defaultValue;
  if (canOverrideEnvVariables) {
    const stored = localStorage.getItem(key);
    if (stored) {
      return stored;
    }
  }
  if (defaultValue) {
    localStorage.setItem(key, defaultValue);
  }
  return defaultValue;
};

export const xPrimerBranch = getOverrideableEnvVariable(
  'x-primer-branch Header',
  import.meta.env.X_PRIMER_BRANCH,
);

export const shouldAddXPrimerBranchHeader = (url: string) =>
  canOverrideEnvVariables &&
  xPrimerBranch &&
  /api\.(dev|staging)\.(core|data)\.primer\.io/.test(url);

export const allowToConfigureAllConnections =
  import.meta.env.VITE_ALLOW_TO_CONFIGURE_ALL_CONNECTIONS === 'true';

export const apiUrl = getOverrideableEnvVariable(
  'API url',
  import.meta.env.VITE_API_URL,
);
export const workflowBuilderApiUrl = getOverrideableEnvVariable(
  'Workflows API URL',
  import.meta.env.VITE_WORKFLOWS_API_URL,
);
export const accountsApiUrl = getOverrideableEnvVariable(
  'Accounts API URL',
  import.meta.env.VITE_ACCOUNTS_API_URL,
);

export const primerEtlApiUrl = getOverrideableEnvVariable(
  'ETL API URL',
  import.meta.env.VITE_PRIMER_ETL_API_URL,
);
export const paymentIntegrationsConfigApiUrl = getOverrideableEnvVariable(
  'Payment integration API URL',
  import.meta.env.VITE_PAYMENT_INTEGRATIONS_CONFIG_API_URL,
);
export const paymentTimelineApiUrl = getOverrideableEnvVariable(
  'Payment timeline API URL',
  import.meta.env.VITE_PAYMENT_TIMELINE_API_URL,
);
export const assetsUrl = getOverrideableEnvVariable(
  'Assets URL',
  import.meta.env.VITE_ASSETS_URL,
);

export const goatCdnUrl = getOverrideableEnvVariable(
  'Goat CDN URL',
  import.meta.env.VITE_GOAT_CDN_URL,
);

export const cubeJsApiUrl = import.meta.env.VITE_CUBEJS_API_URL;

export const hotjarAllowTracking =
  import.meta.env.VITE_HOTJAR_ALLOW_TRACKING === 'true';
export const hotjarDebug = import.meta.env.VITE_HOTJAR_DEBUG === 'true';
export const hotjarId = import.meta.env.VITE_HOTJAR_ID;
export const hotjarSV = import.meta.env.VITE_HOTJAR_SV;

export const refetchOnWindowFocus = Boolean(
  import.meta.env.VITE_REFETCH_ON_WINDOW_FOCUS,
);
export const datoAdminToken = import.meta.env.VITE_DATO_ADMIN_TOKEN;

export const launchDarklyClientId = import.meta.env
  .VITE_LAUNCH_DARKLY_CLIENT_ID;
export const nangoApiUrl = getOverrideableEnvVariable(
  'Nango API URL',
  import.meta.env.VITE_NANGO_API_URL,
);

export const beamerProductId = import.meta.env.VITE_BEAMER_PRODUCT_ID;
