import { useFeatureFlags } from 'SRC/hooks/useFeatureFlags';

/**
 * A utility for hiding payment methods according to the HIDE_UNREADY_APMS feature flag.
 *
 * @returns a function which can be used to filter if a payment method id can be filtered.
 */
export const useFilterUnreadyAPMs = (): ((
  fullPaymentMethodId: string,
) => boolean) => {
  const { hideUnreadyApms } = useFeatureFlags();

  return (fullPaymentMethodId) =>
    !(
      hideUnreadyApms?.amps &&
      Array.isArray(hideUnreadyApms.amps) &&
      hideUnreadyApms.amps?.includes(fullPaymentMethodId)
    );
};
