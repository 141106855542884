import { useResizeDetector } from 'react-resize-detector';
import { useCallback, useState, useRef, useLayoutEffect } from 'react';

type OverflowType = 'text' | 'horizontal' | 'vertical';

/**
 * If type === undefined, then we check all overflows.  In this case useOverflow will return false if
 * all types of overflow are false (ie !text && !horizontal && !vertical),
 * and will return true if any of the overflow types are true (ie, text || overflow || vertical)
 * */
export const useOverflowDetector = (type?: OverflowType) => {
  const ref = useRef<HTMLElement>();
  const [overflowing, setOverflowing] = useState(false);

  const checkOverflow = useCallback(() => {
    const { current } = ref;
    if (current) {
      const hasMatchingOverflow =
        ((type === undefined || type === 'text') &&
          // based on here https://stackoverflow.com/questions/7738117/html-text-overflow-ellipsis-detection
          current.offsetWidth < current.scrollWidth) ||
        ((type === undefined || type === 'vertical') &&
          current.scrollHeight > current.clientHeight) ||
        ((type === undefined || type === 'horizontal') &&
          current.scrollWidth > current.clientWidth);

      setOverflowing(hasMatchingOverflow);
    }
  }, [type]);

  //   skipOnMount: false alone is not enough for it to fire on mount
  useLayoutEffect(checkOverflow);

  useResizeDetector({ onResize: checkOverflow, skipOnMount: false });

  return { ref, overflowing };
};
