export const metaData = {
  ALIPAY: {
    colors: {
      background: '#31B1F0',
    },
  },

  BANCONTACT: {
    colors: {
      background: '#FFFFFF',
    },
  },
  BANCONTACT_CARD: {
    colors: {
      background: '#FFFFFF',
    },
  },
  DIRECT_DEBIT: {
    colors: {
      background: '#231F20',
    },
  },
  DOTPAY: {
    colors: {
      background: '#FFFFFF',
    },
  },
  //   TODO Not sure if a payment method or not
  EPS: {
    colors: {
      background: '#FFFFFF',
    },
  },
  GCASH: {
    colors: {
      background: '#007CFF',
    },
  },
  GRABPAY: {
    colors: {
      background: '#00B45E',
    },
  },
  GIROPAY: {
    colors: {
      background: '#000268',
    },
  },
  IDEAL: {
    colors: {
      background: '#CC0670',
    },
  },
  INTERAC: {
    colors: {
      background: '#FDB913',
    },
  },
  LAYBUY: {
    colors: {
      background: '#7372EE',
    },
  },
  MBWAY: {
    colors: {
      background: '#FFFFFF',
    },
  },
  MOBILEPAY: {
    colors: {
      background: '#5A78FF',
    },
  },
  NETS: {
    colors: {
      background: '#FFFFFF',
    },
  },
  PAGOFACIL: {
    colors: {
      background: '#FFFFFF',
    },
  },
  PAYCONIQ: {
    colors: {
      background: '#F74481',
    },
  },
  // Could copy it from existing payment method type,
  // but just hardcode for now since others are hardcoded
  PAYPAL: {
    colors: {
      background: '#BEE4FE',
    },
  },

  PAYSHOP: {
    colors: {
      background: '#FFFFFF',
    },
  },
  PAYTRAIL: {
    colors: {
      background: '#FFFFFF',
    },
  },
  SOFORT: {
    colors: {
      background: '#EF809F',
    },
  },
  // TODO this is number 49, see if we have sofort vs sofort banking??
  SOFORT_BANKING: {
    colors: {
      background: '#EF809F',
    },
  },
  // TODO Not sure if payment method or not
  TINK: {
    colors: {
      background: '#F89572',
    },
  },
  TWINT: {
    colors: {
      background: '#000000',
    },
  },

  TRUSTLY: {
    colors: {
      background: '#0EE06E',
    },
  },

  VIPPS: {
    colors: {
      background: '#FF5B24',
    },
  },
  //   TODO Not sure if payment method or not
  WORLDLINE: {
    colors: {
      background: '#41B4D2',
    },
  },

  P24: {
    colors: {
      background: '#C51B2A',
    },
  },

  GIFTCARD: {
    colors: {
      background: '#FFFFFF',
    },
  },

  PAYNOW: {
    colors: {
      background: '#941F7F',
    },
  },
  AFFIRM: {
    colors: {
      background: '#4A4AF4',
    },
  },
  FAST: {
    colors: {
      background: '#FFFFFF',
    },
  },
  POLI: {
    colors: {
      background: '#2F4398',
    },
  },
  PROMPTPAY: {
    colors: {
      background: '#023C68',
    },
  },

  DANA: {
    colors: {
      background: '#108DE8',
    },
  },
  OVO: {
    colors: {
      background: '#4B2489',
    },
  },
  SHOPEEPAY: {
    colors: {
      background: '#EE3929',
    },
  },
  BANCONTACT_PAYCONIQ: {
    colors: {
      background: '#F74481',
    },
  },
  KAARTDIRECT: {
    colors: {
      background: '#FFFFFF',
    },
  },
  CARD: {
    colors: {
      background: '#FFFFFF',
    },
  },
};

export const staticData = {
  PaymentCardConnection: {
    id: 'PAYMENT_CARD',
    internalVersion: 1,
    $searchable: ' payment_card card  ',
    isVerified: false,
    isDiscoverable: false,
    supportsMetadataMappings: false,
    oauthUrl: null,
    isConfigurable: false,
    primaryType: 'PAYMENT_METHOD',
    supportsMultipleConfigs: false,
    externalLinks: {
      website: '',
    },
    copy: {
      name: 'Card',
      description: '',
      instructions: {
        configurableFields: null,
        webhookSetup: null,
        additional: [],
      },
    },
    visuals: {
      assets: {
        logo: 'payment-card',
        icon: 'payment-card',
        preview: '',
        banner: '',
      },
      colors: {
        main: '#8F6AEA',
        contrast: '#fff',
        background: '#8F6AEA',
      },
    },
    compatibility: {
      markets: [],
      currencies: [],
      features: [],
    },
    provides: [],
    configurableFields: [],
  },
  ThreeDSConnection: {
    id: '3DS',
    internalVersion: 1,
    $searchable: ' 3ds 3ds  ',
    isVerified: false,
    primaryType: 'OTHER',
    isDiscoverable: false,
    supportsMetadataMappings: false,
    oauthUrl: null,
    isConfigurable: false,
    supportsMultipleConfigs: false,
    externalLinks: {
      website: '',
    },
    copy: {
      name: '3DS',
      description: '',
      instructions: {
        configurableFields: null,
        webhookSetup: null,
        additional: [],
      },
    },
    visuals: {
      assets: {
        logo: '3DS',
        icon: '3DS',
        preview: '',
        banner: '',
      },
      colors: {
        main: '#000',
        contrast: '#fff',
        background: '#000',
      },
    },
    compatibility: {
      markets: [],
      currencies: [],
      features: [],
    },
    provides: [],
    configurableFields: [],
  },
  UnknownConnection: {
    id: '__UNKNOWN__',
    isVerified: false,
    internalVersion: 1,
    $searchable: ' unknown  ',
    paymentMethodId: null,
    primaryType: 'OTHER',
    supportsMultipleConfigs: false,
    oauthUrl: null,
    isConfigurable: false,
    isDiscoverable: false,
    supportsMetadataMappings: false,
    externalLinks: {
      website: '',
    },
    copy: {
      name: 'Unknown',
      description: '',
      instructions: {
        configurableFields: null,
        webhookSetup: null,
        additional: [],
      },
    },
    visuals: {
      assets: {
        logo: '',
        icon: '',
        preview: '',
        banner: '',
      },
      colors: {
        main: '',
        contrast: '',
        background: '',
      },
    },
    compatibility: {
      markets: [],
      currencies: [],
      features: [],
    },
    provides: [],
    configurableFields: [],
  },
};
