import { createMutation } from 'LEGACY/api/mutation';
import { Company } from 'SRC/hooks/useIdentity';
import { accountsApiUrl } from 'SRC/config';

type AuthOptionsPayload =
  | { initiator: 'LOGIN'; userEmail: string }
  | { initiator: 'INVITE'; inviteCode: string; userEmail: string };

interface Response {
  redirectUrl: string | null;
}

export const useAuthOptions = createMutation<AuthOptionsPayload, Response>({
  api: {
    url: '/auth/options',
    apiBaseUrl: accountsApiUrl,
    method: 'POST',
    authenticate: false,
    content: 'json',
    params: {
      onError: {
        message: 'Unable to process email address',
      },
    },
  },
});

type LoginResponse = {
  accessToken: string;
  isInternalUser: boolean;
  scopes: string[];
  primerAccounts: Company[];
};

export const useForwardSso = createMutation<never, LoginResponse>({
  api: {
    authenticate: false,
    url: ({ $props }) => `/sso/callback/${$props.action}${$props.search}`,
    apiBaseUrl: accountsApiUrl,
    method: 'GET',
    params: { onError: { skipAll: true }, skipReloadOnUnauthorized: true },
  },
  mutation: {
    throwOnError: true,
  },
});
