import {
  CURRENCY_INFORMATION,
  fetchCurrencyInformation,
} from 'SRC/fetch/api/currencyInformation';
import useSWRImmutable from 'swr/immutable';
import { useIdentity } from './useIdentity';

export let currencyInformation = new Map<string, number>();

export const setCurrencyInformation = (data: typeof currencyInformation) => {
  currencyInformation = data;
};

export const useCurrencyInformation = () => {
  const { loggedIn } = useIdentity();

  const { isLoading, error } = useSWRImmutable(
    loggedIn ? CURRENCY_INFORMATION : null,
    fetchCurrencyInformation,
    {
      onSuccess: setCurrencyInformation,
    },
  );

  if (error) throw error;

  return { isLoading };
};
