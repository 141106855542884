import { Toast, ToastProps } from '@primer-io/goat';
import { ReactNode } from 'react';
import { create } from 'zustand';

type ToastAction = {
  content: ReactNode;
  altText: string;
  onClick: () => void;
};

type ToastConfig = ToastProps & {
  title?: ReactNode;
  description?: ReactNode;
  action?: ToastAction;
};

interface State {
  toasts: ToastConfig[];
  addToast: (toast: ToastConfig) => void;
}

const useToastStore = create<State>((set) => ({
  toasts: [],
  addToast: (toast) => set(({ toasts }) => ({ toasts: [...toasts, toast] })),
}));

export const toast: Record<
  NonNullable<ToastProps['sentiment']>,
  (config: ToastConfig) => void
> = {
  neutral: (config: ToastConfig) =>
    useToastStore.getState().addToast({ ...config }),
  positive: (config: ToastConfig) =>
    useToastStore.getState().addToast({ ...config, sentiment: 'positive' }),
  negative: (config: ToastConfig) =>
    useToastStore.getState().addToast({ ...config, sentiment: 'negative' }),
  informative: (config: ToastConfig) =>
    useToastStore.getState().addToast({ ...config, sentiment: 'informative' }),
  attentive: (config: ToastConfig) =>
    useToastStore.getState().addToast({ ...config, sentiment: 'attentive' }),
};

export function ToastContainer() {
  const toasts = useToastStore((state) => state.toasts);

  return (
    <>
      {toasts.map(
        ({ title, description, action, icon = true, ...rest }, index) => (
          <Toast key={index} icon={icon} dismissable {...rest}>
            {title && <Toast.Title>{title}</Toast.Title>}
            {description && (
              <Toast.Description>{description}</Toast.Description>
            )}

            {action && (
              <>
                <Toast.Action altText={action.altText} onClick={action.onClick}>
                  {action?.content}
                </Toast.Action>
              </>
            )}
          </Toast>
        ),
      )}
    </>
  );
}
