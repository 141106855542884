import {
  isValidElement,
  cloneElement,
  useState,
  useCallback,
  useEffect,
  ReactNode,
} from 'react';
import { Popover, Modal } from 'antd';

import Button from './Button';
import Card from './Card';
import Breadcrumbs from './Breadcrumbs';
import Text from '../typography/Text';
import Space from '../layout/Space';
import Theme from '../theme';

type Sentiment = 'important' | 'negative';
type Status = 'pending' | 'loading' | 'error' | 'success';

type ContentOwnProps = {
  pendingContent?: ReactNode;
  onConfirm: (evt: unknown) => void;
  confirmButton?: ReactNode;

  sentiment?: Sentiment;

  /**
   * `pending` is alias for `idle`
   * @default pending / idle
   */
  status?: 'idle' | 'pending' | 'loading' | 'error' | 'success';

  onCancel?: (evt: unknown) => void;
  cancelButton?: string | ReactNode;

  /**
   * @default [loading, success]
   */
  disableConfirmOnStatus?: Status[];

  /**
   * @default [loading, success]
   */
  disableCancelOnStatus?: Status[];

  loadingContent?: ReactNode;
  errorContent?: ReactNode;
  successContent?: ReactNode;

  width?: number;
};

type TooltipOwnProps = {
  triggerElement?: JSX.Element;

  /**
   * @default top
   */
  position?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';

  /**
   * @default false
   *
   * ignored when in controlled mode
   */
  initialOpen?: boolean;

  /**
   * @default on_success_status
   */
  closeMode?: 'on_success_status' | 'immediately';

  /**
   * @default 2
   */
  closeDelaySec?: number;

  controlled?: {
    open: boolean;
    setOpen: (open: boolean) => void;
  };
};

type ModalOwnProps = {
  /**
   * @default false
   */
  visible: boolean;

  onConfirm?: (evt: unknown) => void;
  onClose?: () => void;
};

const DISABLED_ON_STATUS_DEFAULTS = ['loading', 'success'];
const CONTENT_WIDTH = 300;

export function Content(props: ContentOwnProps) {
  const {
    status = 'pending',
    pendingContent,
    loadingContent,
    errorContent,
    successContent,
    sentiment = 'important',
    onConfirm,
    onCancel,
    confirmButton,
    cancelButton = 'Cancel',
    disableConfirmOnStatus,
    disableCancelOnStatus,
    width,
  } = props;

  const buttons: ReactNode[] = [];

  // Cancel
  buttons.push(
    isValidElement(cancelButton) ? (
      cancelButton
    ) : (
      <Button
        styl='secondary'
        onClick={onCancel}
        loading={status === 'loading'}
        disabled={(
          disableCancelOnStatus ?? DISABLED_ON_STATUS_DEFAULTS
        ).includes(status)}
        data-testid='confirmdialog-button-cancel'
      >
        {cancelButton}
      </Button>
    ),
  );

  // Confirm
  buttons.push(
    isValidElement(confirmButton) ? (
      confirmButton
    ) : (
      <Button
        // @ts-expect-error
        type='primary'
        sentiment={sentiment === 'important' ? 'interactive' : sentiment}
        onClick={onConfirm}
        loading={status === 'loading'}
        disabled={(
          disableConfirmOnStatus ?? DISABLED_ON_STATUS_DEFAULTS
        ).includes(status)}
        data-testid='confirmdialog-button-confirm'
      >
        {confirmButton}
      </Button>
    ),
  );

  let content = {
    pending: pendingContent,
    idle: pendingContent,
    loading: loadingContent ?? pendingContent,
    error: errorContent ?? pendingContent,
    success: successContent ?? pendingContent,
  }[status];

  if (typeof content === 'string') {
    content = <Text wrap>{content}</Text>;
  }

  return (
    <Card
      styl='regular'
      elevation='flat'
      style={{
        width: width || CONTENT_WIDTH,
        flexGrow: 0,
        flexShrink: 0,
        padding: '8px 4px', // We can't change the inner padding of Antd's popover, so we need to adjust our Card's padding instead
      }}
    >
      {content}
      <Space of='col24' />
      <Breadcrumbs
        $wrap={false}
        items={buttons}
        separator={<Space of='row12' />}
        align='end'
      />
    </Card>
  );
}

export default function ConfirmationDialog(
  props: ContentOwnProps & TooltipOwnProps,
) {
  const {
    triggerElement,
    position = 'top',
    initialOpen = false,
    closeMode = 'on_success_status',
    closeDelaySec = 2,
    controlled,
    ...contentProps
  } = props;

  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = controlled ? controlled.open : uncontrolledOpen;
  const setOpen = useCallback(
    (newOpen) => {
      if (controlled) {
        controlled.setOpen(newOpen);
      } else {
        setUncontrolledOpen(newOpen);
      }
    },
    [controlled],
  );

  const closingFn = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const cancellingFn = (evt) => {
    closingFn();
    if (contentProps.onCancel) {
      contentProps.onCancel(evt);
    }
  };

  const confirmingFn = (evt) => {
    contentProps.onConfirm(evt);
    if (closeMode === 'immediately') {
      closingFn();
    }
  };

  const content = (
    <Content
      {...contentProps}
      onCancel={cancellingFn}
      onConfirm={confirmingFn}
    />
  );

  useEffect(() => {
    if (
      contentProps.status === 'success' &&
      closeMode === 'on_success_status'
    ) {
      if (!closeDelaySec) {
        closingFn();
      } else {
        window.setTimeout(closingFn, closeDelaySec * 1000);
      }
    }
  }, [contentProps, closeMode, closingFn, closeDelaySec]);

  return (
    <Popover
      content={content}
      placement={position}
      trigger='click'
      visible={open}
    >
      {triggerElement
        ? cloneElement(triggerElement, {
            onClick: (...args) => {
              setOpen(true);
              triggerElement.props.onClick?.(...args);
            },
          })
        : null}
    </Popover>
  );
}

export function DeleteConfirmationDialog(
  props: ContentOwnProps &
    TooltipOwnProps & { verb?: 'delete' | 'revoke' | 'cancel' | 'archive' },
) {
  const { verb = 'delete' } = props;
  const {
    pendingContent = {
      delete: 'Please confirm you want to delete this.',
      revoke: 'Please confirm you want to revoke this.',
      cancel: 'Please confirm you want to cancel this.',
      archive: 'Please confirm you want to archive this.',
    }[verb],
    loadingContent = {
      delete: 'Deleting...',
      revoke: 'Revoking...',
      cancel: 'Cancelling...',
      archive: 'Archiving...',
    }[verb],
    successContent = {
      delete: 'Deleted successfully',
      revoke: 'Revoked successfully',
      cancel: 'Cancelled successfully',
      archive: 'Archived successfully',
    }[verb],
    confirmButton = {
      delete: 'Yes, delete',
      revoke: 'Yes, revoke',
      cancel: 'Yes, cancel',
      archive: 'Yes, archive',
    }[verb],
  } = props;
  return (
    <ConfirmationDialog
      sentiment='negative'
      pendingContent={pendingContent}
      loadingContent={loadingContent}
      successContent={successContent}
      confirmButton={confirmButton}
      {...props}
    />
  );
}

type ConfirmationModalOwnProps = Omit<ContentOwnProps, 'onConfirm'> &
  ModalOwnProps;

export function ConfirmationModal(props: ConfirmationModalOwnProps) {
  const { visible, onClose, width, ...contentProps } = props;

  const closingFn = () => {
    onClose && onClose();
  };

  const cancellingFn = (evt) => {
    contentProps.onCancel && contentProps.onCancel(evt);
    closingFn();
  };

  const confirmingFn = (evt) => {
    contentProps.onConfirm && contentProps.onConfirm(evt);
    closingFn();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      footer={null}
      width={`calc(${width || CONTENT_WIDTH}px + 2 * ${Theme.paddingRow16})`}
      bodyStyle={{ padding: `${Theme.paddingColumn12} ${Theme.paddingRow16}` }}
    >
      <Content
        {...contentProps}
        onCancel={cancellingFn}
        onConfirm={confirmingFn}
        width={width}
      />
    </Modal>
  );
}

export function CancelEditConfirmationModal(props: ConfirmationModalOwnProps) {
  return (
    <ConfirmationModal
      sentiment='important'
      pendingContent='You have unsaved changes. Are you sure you want to cancel?'
      confirmButton='No'
      cancelButton='Yes, cancel'
      {...props}
    />
  );
}
