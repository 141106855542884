export const oAuthScopes = {
  API_KEYS_READ: 'api_keys:read',
  API_KEYS_WRITE: 'api_keys:write',
  APPLE_PAY_SESSION_REQUEST: 'apple_pay:session',
  CLIENT_TOKENS_WRITE: 'client_tokens:write',
  CLIENT_SDK_CONFIGURATION: 'client_sdk:configuration',
  DISPUTES_READ: 'disputes:read',
  EXCHANGE_MULTI_USE_TOKEN: 'tokenize:exchange_multi_use_token',
  METRICS_READ: 'metrics:read',
  MULTI_FACTOR_CONFIRM: 'multi_factor:confirm',
  NOTIFICATIONS_READ: 'notifications:read',
  NOTIFICATIONS_WRITE: 'notifications:write',
  OBSERVABILITY_PAYMENTS_WRITE: 'observability-payments:write',
  PAYMENT_METHODS_READ: 'payment_methods:read',
  PAYMENT_METHODS_WRITE: 'payment_methods:write',
  PAYPAL_BILLING_AGREEMENTS: 'paypal:billing_agreements',
  PAYPAL_ORDERS: 'paypal:orders',
  GOCARDLESS_MANDATES: 'gocardless:mandates',
  KLARNA_SESSIONS: 'klarna:sessions',
  TAX_CALCULATION_SESSION: 'tax_calculation:session',
  PRIMER_ACCOUNTS_READ: 'accounts:read',
  PRIMER_ACCOUNTS_WRITE: 'accounts:write',
  PRIMER_ACCOUNTS_SWITCH: 'accounts:switch',
  PROCESSORS_READ: 'processors:read',
  PROCESSORS_WRITE: 'processors:write',
  PROFILE_READ: 'profile:read',
  PROFILE_WRITE: 'profile:write',
  THIRD_PARTY_WEBHOOK_TRIGGER: 'third_party:webhook_trigger',
  THREE_D_SECURE_NEGOTIATION: 'three_ds:negotiation',
  THREE_D_SECURE_AUTH: 'three_ds:auth',
  THREE_D_SECURE_VERIFY: 'three_ds:verify',
  THREE_D_SECURE_ONBOARD: 'three_ds:onboard',
  LOYALTY_TRANSACTIONS_READ: 'loyalty_transactions:read',
  LOYALTY_TRANSACTIONS_WRITE: 'loyalty_transactions:write',
  TOKENIZE_PAYMENT_INSTRUMENT: 'tokenize:payment_instrument',
  PAYMENT_INSTRUMENTS_WRITE: 'payment_instrument:write',
  PAYMENT_INSTRUMENTS_READ: 'payment_instrument:read',
  TRANSACTIONS_AUTHORIZE: 'transactions:authorize',
  TRANSACTIONS_CANCEL: 'transactions:cancel',
  TRANSACTIONS_CAPTURE: 'transactions:capture',
  TRANSACTIONS_READ: 'transactions:read',
  TRANSACTIONS_REFUND: 'transactions:refund',
  USERS_READ: 'users:read',
  USERS_WRITE: 'users:write',
  WORKFLOWS_READ: 'workflows:read',
  WORKFLOWS_WRITE: 'workflows:write',
  USER_INVITES_WRITE: 'invitations:write',
  USER_INVITES_READ: 'invitations:read',
  USER_ROLES_WRITE_LEGACY: 'user-roles:write',
  USER_ROLES_WRITE: 'user_roles:write',
  RESUME_TOKENS_READ: 'resume_tokens:read',
  RESUME_TOKENS_WRITE: 'resume_tokens:write',
  ROLES_READ: 'roles:read',
  CHECKOUT_CONFIG_READ: 'checkout-config:read',
  CHECKOUT_CONFIG_WRITE: 'checkout-config:write',
};

export type OAuthScopeKeys = keyof typeof oAuthScopes;
