import { CSSProperties } from 'react';
import styled from 'styled-components';

import * as utils from '../_utils';
import Theme from '../theme';

import type { Sentiment } from '../_utils';

type Style = 'box' | 'section' | 'page';

const sizing: { [K in Style]: { fontSize: string; lineHeight: string } } = {
  box: { fontSize: '20px', lineHeight: '32px' },
  section: { fontSize: '28px', lineHeight: '40px' },
  page: { fontSize: '40px', lineHeight: '48px' },
};

type OwnProps = {
  children: any;
  styl: Style;
  wrap?: boolean;
  maxWrapLines?: number;
  sentiment?: Sentiment;
  bold?: boolean;
  style?: CSSProperties;
};

const Root = styled.div<
  OwnProps & {
    _wrap?: boolean;
  }
>`
  ${(props) =>
    props.maxWrapLines! > 1 ? 'display: -webkit-box;' : 'display: block;'}
  ${(props) =>
    props._wrap &&
    `
  flex-wrap: wrap;
  white-space: normal;
  word-wrap: break-word;
  `};
  ${(props) =>
    !props._wrap &&
    `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `};

  ${(props) =>
    props.maxWrapLines! > 1 &&
    `
  -webkit-box-orient: vertical;
  -webkit-box-align: start !important;
  -webkit-line-clamp: ${props.maxWrapLines};
  overflow: hidden;
  text-overflow: ellipsis;
  `};

  align-items: center;

  font-family: ${Theme.fontFamilyTitle};
  font-weight: ${(props) => (props.bold ? '500' : '400')};

  ${(props) => `font-size: ${sizing[props.styl].fontSize}`};
  ${(props) => `line-height: ${sizing[props.styl].lineHeight}`};
  ${(props) =>
    `color: ${utils.getSentimentColors(props.sentiment ?? 'regular').fg}`};
`;

export default function Title(props: OwnProps) {
  const { children, wrap, ...otherProps } = props;

  return (
    <Root {...otherProps} _wrap={wrap}>
      {children}
    </Root>
  );
}

type StyleConsumed = Omit<OwnProps, 'styl'>;

export function BoxTitle(props: StyleConsumed) {
  return <Title {...props} styl='box' />;
}

export function SectionTitle(props: StyleConsumed) {
  return <Title {...props} styl='section' />;
}

export function PageTitle(props: StyleConsumed) {
  return <Title {...props} styl='page' />;
}
