import { paymentIntegrationsConfigApiUrl } from 'SRC/config';
import { get } from 'SRC/fetch/fetch';

export interface ConnectionsDefinitionsResponse {
  data: ConnectionDefinition[];
}

export interface ConnectionDefinition {
  id: string;
  primaryType: PrimaryTypes;
  isPrimerApp?: boolean;
  isVerified: boolean;
  internalVersion: number;
  isConfigurable: boolean;
  isDiscoverable: boolean;
  supportsMultipleConfigs: boolean;
  supportsMetadataMappings: boolean;
  spec?: {
    features: Record<string, string>;
  };
  externalLinks?: { website?: string };
  copy: ConnectionCopy;
  visuals: ConnectionVisuals;
  compatibility: {
    markets: string[];
    currencies: string[];
    features: string[];
  };
  provides: ConnectionsProviders[];
  configurableFields: ConfigurableField[];
  oauthUrl: null | string;
}

export interface ConnectionVisuals {
  colors: {
    main: string;
    contrast: string;
    background: string;
  };
  assets: {
    logo: string;
    icon: string;
    preview: null | string;
    banner: null | string;
  };
}

export interface ConnectionCopy {
  name: string;
  description: string;
  instructions: {
    configurableFields: null | string;
    webhookSetup: null | string;
    additional: { heading: string; required: boolean; body: string }[];
  };
}

export type EnumDisplayData = {
  value: string;
  displayValue: string;
  hint: string;
};
export interface ConfigurableField {
  id: string;
  name: string;
  description: null | string;
  url: null;
  editable: boolean;
  type: string;
  enum: string[] | null;
  credential: boolean;
  required: boolean;
  enumDisplayData?: EnumDisplayData[];
}

export type PrimaryTypes =
  | 'FRAUD_DETECTION_PROVIDER'
  | 'APPLICATION'
  | 'PAYMENT_METHOD'
  | 'PROCESSOR'
  // eslint-disable-next-line
  | (string & {});

export interface ConnectionsProviders {
  type: PrimaryTypes;
  id: string;
  supportedFunctions: unknown;
  transactionLinks?: {
    sandbox: string;
    production: string;
  } | null;
  supportedProcessors?: string[];
  supportedPaymentMethods?: string[];
  supportedPaymentMethodsV2?: PaymentMethodDataV2[];
  midFetchMode?: 'MANUAL' | 'AUTOMATIC' | 'NONE' | null;
  midExtraColumns?: string[];
}

export interface PaymentMethodDataV2 {
  id: string;
  name: string;
  icon: string;
}

export const CONNECTIONS_DEFINITIONS_URL = '/connections/definitions';
export const fetchConnectionsMeta = () => {
  return get<ConnectionsDefinitionsResponse>(
    `${paymentIntegrationsConfigApiUrl}${CONNECTIONS_DEFINITIONS_URL}`,
    {},
    { auth: true }, // Assuming authentication is required, adjust accordingly
  );
};
