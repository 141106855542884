import List from 'LEGACY/layout/List';
import { Footnote } from 'LEGACY/typography/Text';

type OwnProps = {
  errorMessages?: string[];
};

export default function Errors(props: OwnProps) {
  const { errorMessages, ...otherProps } = props;

  if (!errorMessages || errorMessages.length === 0) return null;

  return (
    <List separator='' {...otherProps}>
      {errorMessages.map((err, idx) => (
        <Footnote key={idx} sentiment='negative' wrap>
          {err}
        </Footnote>
      ))}
    </List>
  );
}
