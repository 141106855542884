import { toast } from 'SRC/components/Toast';
import {
  CONNECTIONS_DEFINITIONS_URL,
  ConnectionsProviders,
  fetchConnectionsMeta,
} from 'SRC/fetch/api/integrations/definitions';
import { useConnectionsStore } from 'SRC/hooks/useConnectionsInfo/utils/useConnectionsStore';
import { useFilterUnreadyAPMs } from 'SRC/hooks/useConnectionsInfo/utils/useFilterUnreadyAPMs';
import { useIdentity } from 'SRC/hooks/useIdentity';
import useSWR from 'swr';

export const ConnectionInfoFetcher = ({ children }) => {
  const { loggedIn } = useIdentity();
  const filterUnreadyAPMs = useFilterUnreadyAPMs();
  const setData = useConnectionsStore((state) => state.setData);

  useSWR(loggedIn ? CONNECTIONS_DEFINITIONS_URL : null, fetchConnectionsMeta, {
    onSuccess: (newData) => {
      const adjustedDataConnections = newData.data.map((definition) => {
        return {
          ...definition,
          provides: definition.provides?.map((providesDefinition) =>
            processProvides(providesDefinition, filterUnreadyAPMs),
          ),
        };
      });

      setData(adjustedDataConnections);
    },
    onError: (error) => {
      toast.negative({ description: error.message });
    },
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
  });

  return <>{children}</>;
};

function processProvides(
  provider: ConnectionsProviders,
  filterUnreadyAPMs: (id: string) => boolean,
) {
  if (!provider?.supportedPaymentMethodsV2) {
    return provider;
  }

  return {
    ...provider,
    supportedPaymentMethodsV2: provider.supportedPaymentMethodsV2.filter(
      (paymentMethod) => filterUnreadyAPMs(paymentMethod.id),
    ),
  };
}
