import { useNavigate, useLocation } from 'react-router-dom';
import { useIdentity } from 'SRC/hooks/useIdentity';
import { useConfirmSession } from '../api';
import { LoginTwoFactorCard } from '../components/login_invite/cards/LoginTwoFactorCard';
import { getRedirect } from '../utils';

export function LoginMfa() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logIn } = useIdentity();

  const [confirmSession, { error: confirmError }] = useConfirmSession({
    onSuccess({ accessToken, scopes }) {
      logIn(accessToken, scopes);
      navigate(getRedirect(location.search) || '/');
    },
  });

  return (
    <LoginTwoFactorCard
      formProps={{
        onSubmit: confirmSession,
        externalError: confirmError
          ? 'Verification failed, your code may be invalid'
          : undefined,
      }}
    />
  );
}
