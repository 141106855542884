import { Children, CSSProperties, Fragment, ReactNode } from 'react';

import styled from 'styled-components';

type OwnProps = {
  items: ReactNode;
  separator?: ReactNode;
  className?: string;
  style?: CSSProperties;

  /**
   * @default start
   */
  align?: 'start' | 'end' | 'center';
  /**
   * @default center
   */
  vAlign?: 'start' | 'end' | 'center';
  /**
   * @default true
   */
  $wrap?: boolean;
  wrap?: boolean;
};

const Root = styled.div<{
  alignItems?: string;
  $wrap?: boolean;
  justifyContent?: string;
}>`
  display: flex;
  align-items: ${(props) => props.alignItems ?? 'center'};
  flex-wrap: ${(props) => (props.$wrap ? 'wrap' : 'nowrap')};
  justify-content: ${(props) => props.justifyContent ?? 'flex-start'};
`;

const alignmentMap = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
};

export default function Breadcrumbs(props: OwnProps) {
  const {
    items,
    separator,
    align = 'start',
    vAlign = 'center',
    wrap = true,
    ...otherProps
  } = props;
  const itemsArr = Children.toArray(items);
  const justifyContent = alignmentMap[align] ?? 'flex-start';
  const alignItems = alignmentMap[vAlign] ?? 'center';

  return (
    <Root
      justifyContent={justifyContent}
      alignItems={alignItems}
      $wrap={wrap}
      {...otherProps}
    >
      {itemsArr.map((itm, index) => (
        <Fragment key={index}>
          {itm}
          {index + 1 !== itemsArr.length && separator}
        </Fragment>
      ))}
    </Root>
  );
}
