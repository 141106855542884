import { roles as rolesEnum } from 'MODULES/auth/const/roles';
import { oAuthScopes } from 'MODULES/auth/const/scopes';
import { ACCESS_TOKEN_ID, SCOPES_ID } from 'SRC/fetch/api/users';
import { isLocal } from 'UTILS/env';
import { PermissionExpression, User } from './types';
import { getLocalStorage } from 'UTILS/getLocalStorage';

export type OauthStateKey = 'app' | 'integration';

export type OauthState = {
  [key in OauthStateKey]?: string;
} & {
  url: string;
  marketplace: 'apps' | 'integrations';
  name: string;
  internalSearch: string;
  instanceId?: string;
};

const PERMISSION_WILDCARD = '*';

export function saveTokenToStorage(token) {
  getLocalStorage().setItem(ACCESS_TOKEN_ID, token);
}

export function getTokenFromStorage(): string {
  return getLocalStorage().getItem(ACCESS_TOKEN_ID) || '';
}

export function saveScopesToStorage(scopes) {
  getLocalStorage().setItem(SCOPES_ID, JSON.stringify(scopes));
}

export function saveStateToStorage(nonce: string, state: OauthState) {
  getLocalStorage().setItem(nonce, JSON.stringify(state));
}

export function getStateFromStorage(nonce: string) {
  try {
    return JSON.parse(getLocalStorage().getItem(nonce) || '');
  } catch (e) {
    console.error('Error reading state from storage, invalid nonce');
    return '';
  }
}

export function clearStateFromStorage(nonce: string) {
  getLocalStorage().removeItem(nonce);
}

export function clearIdentityFromStorage() {
  getLocalStorage().removeItem(ACCESS_TOKEN_ID);
  getLocalStorage().removeItem(SCOPES_ID);
}

export const isTwoFactorProtected = (scopes: string[]) =>
  scopes.length === 1 && scopes[0] === 'multi_factor:confirm';

const knownRoles = Object.keys(rolesEnum);
const knownScopes = Object.values(oAuthScopes);

function checkExpression(
  type: 'role' | 'scope' | 'role/scope',
  expression: string,
) {
  if (expression === PERMISSION_WILDCARD) return;

  const isKnown =
    type === 'role'
      ? knownRoles.includes(expression)
      : type === 'scope'
        ? knownScopes.includes(expression)
        : knownRoles.includes(expression) || knownScopes.includes(expression);

  if (isKnown) return;

  const message = `Trying to use uknown ${type}: ${expression}`;

  if (isLocal()) throw new Error(message);

  console.error(message);
}

export function canDo(
  scopes: undefined | null | string[],
  roles: null | string[],
  expression: PermissionExpression,
): boolean {
  if (typeof expression === 'string') {
    checkExpression('role/scope', expression);
  }

  if (!scopes && !roles) return false;

  const rolesToUse = roles || [];
  const scopesToUse = scopes || [];

  if (typeof expression === 'string') {
    if (expression === PERMISSION_WILDCARD) return true;

    if (knownRoles.includes(expression)) {
      // Syntax sugar here
      // ADMIN is above all roles, so checking if someone is READONLY for example and the current user is ADMIN should result in `true`
      // otherwise all checks would need to check for (READONLY || ADMIN) which would complicate the code
      return (
        rolesToUse.includes(rolesEnum.ADMIN) || rolesToUse.includes(expression)
      );
    }

    if (knownScopes.includes(expression)) {
      return scopesToUse.includes(expression);
    }

    return false;
  }

  if (typeof expression === 'function') {
    const scopesSet = new Set(scopesToUse);
    const rolesSet = new Set(rolesToUse);
    // Using closure to keep the Set alive - otherwise it would be garbage collected
    const has = (value) => {
      checkExpression('scope', value);
      if (value === PERMISSION_WILDCARD) return true;
      return scopesSet.has(value);
    };
    const is = (value) => {
      checkExpression('role', value);
      if (value === PERMISSION_WILDCARD) return true;
      return rolesSet.has(rolesEnum.ADMIN) || rolesSet.has(value);
    };
    return expression({ has, is });
  }

  return false;
}
export const getCompany = (user: User | null) => {
  if (!user) {
    return null;
  }
  return (
    user.primerAccountList.find(
      (acc) => acc.id === user.sessionPrimerAccountId,
    ) ?? null
  );
};
