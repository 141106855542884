import { css } from 'styled-components';

export const buttonReset = css`
  outline: none;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  white-space: nowrap;
  appearance: none;
`;

export const ulReset = css`
  list-style: none;
  padding: 0;
  margin: 0;
`;
