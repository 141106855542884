import styled from 'styled-components';
import Theme from 'LEGACY/theme';

const RootStyled = styled.div`
  width: ${Theme.gridWidthContainerMaxWidth}px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 ${Theme.gridMarginWidth}px;

  // TODO: uncomment this block to create fluid(responsive) behavior, requires optimization of existing pages
  // @media ${Theme.breakpoints.fromMedium} {
  //   width: 100%;
  //   max-width: ${Theme.gridWidthContainerMaxWidth}px;
  // }
`;

type GridWidthContainerProps = Omit<JSX.IntrinsicElements['div'], 'ref'>;

const GridWidthContainer = (props: GridWidthContainerProps) => {
  const { children, ...otherProps } = props;
  return <RootStyled {...otherProps}>{children}</RootStyled>;
};

export default GridWidthContainer;
