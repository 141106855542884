import { Button, Dialog, Icon, P } from '@primer-io/goat';
import { Contact, ExternalLink } from '@primer-io/goat-icons';
import { FC } from 'react';
import { ContactSupportLink } from 'SRC/components/ContactSupport/ContactSupportLink';
import {
  useContactOpenState,
  useSetOpenContactDialog,
} from 'SRC/components/ContactSupport/state';
import { contactStrings } from 'SRC/components/ContactSupport/strings';
import styles from './ContactSupport.module.scss';

export const ContactSupport: FC = () => {
  const openState = useContactOpenState();
  const setOpenState = useSetOpenContactDialog();
  return (
    <Dialog.Root open={openState} onOpenChange={setOpenState}>
      <Dialog.Content
        closeLabel={contactStrings.close}
        radixProps={{
          onEscapeKeyDown: (event) => event.preventDefault(),
          onPointerDownOutside: (event) => event.preventDefault(),
          onInteractOutside: (event) => event.preventDefault(),
        }}
        data-testid='contact-support-dialog'
      >
        <Dialog.Header>
          <Icon as={Contact} />
          {contactStrings.heading}
        </Dialog.Header>

        <Dialog.Body>
          <P className={styles.paragraph}>
            {contactStrings.paragraph1(<ContactSupportLink />)}
          </P>
          <P>{contactStrings.paragraph2}</P>
        </Dialog.Body>

        <Dialog.Footer>
          <Button as='a' href={contactStrings.linkUrl}>
            {contactStrings.buttonLink} <Button.Icon as={ExternalLink} />
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};
