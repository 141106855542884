import { constantCase } from 'change-case';
import { staticData } from 'SRC/hooks/useConnectionsInfo/config/data';
import {
  PAYMENT_METHOD_MAPPINGS,
  PROCESSOR_MAPPINGS,
} from 'SRC/hooks/useConnectionsInfo/config/mappings';
import {
  ConnectionDataMapped,
  ProvideWithSupportedPaymentMethodsV2,
} from 'SRC/hooks/useConnectionsInfo/config/types';
import {
  ConnectionDefinition,
  ConnectionsProviders,
} from 'SRC/fetch/api/integrations/definitions';
import { createGenericPaymentMethodData } from 'SRC/hooks/useConnectionsInfo/utils/createGenericPaymentMethodData';

function extractPaymentMethodId(
  paymentMethodIdWithProcessor: string,
  processorId: string,
) {
  const processorPrefix =
    PROCESSOR_MAPPINGS[processorId] || constantCase(processorId) + '_';
  return paymentMethodIdWithProcessor.replace(processorPrefix, '');
}

/**
 * Transforms a connection definition into a lowercase, space-separated string for easier searching.
 * Spaces are added at the start and end to allow for specific value searches when wrapped with spaces..
 */
export function connectionDefinitionToSearchableString(
  connection: ConnectionDefinition,
) {
  let searchableString = ` ${connection.id.toLowerCase()}`;

  if (connection.copy?.name) {
    searchableString += ` ${connection.copy.name.toLowerCase()}`;
  }

  if (connection.compatibility?.features) {
    searchableString += ` ${connection.compatibility.features
      .join(' ')
      .toLowerCase()}`;
  }

  return searchableString + ' ';
}

function hasSupportedPaymentMethodsV2(
  prov: ConnectionsProviders,
): prov is ProvideWithSupportedPaymentMethodsV2 {
  return (
    prov.type === 'PROCESSOR' && Array.isArray(prov.supportedPaymentMethodsV2)
  );
}

/**
 * Extracts and enriches supported payment methods V2 from the provided data.
 */
export function formatConnections(data: ConnectionDefinition[]) {
  if (!data) return { connectionList: [], connectionsById: new Map() };

  const result: ConnectionDataMapped[] = [
    staticData.PaymentCardConnection,
    staticData.ThreeDSConnection,
  ];
  const existingConnectionIds = new Set();

  const connectionsById = new Map<string, ConnectionDataMapped>(
    result.map((connection) => [connection.id, connection]),
  );

  for (const connection of data) {
    // Add the original connection with the $searchable field
    const mappedConnection: ConnectionDataMapped = {
      ...connection,
      $searchable: connectionDefinitionToSearchableString(connection),
    };
    result.push(mappedConnection);
    existingConnectionIds.add(connection.id);
    connectionsById.set(connection.id, mappedConnection);

    // Skip processing if connection is not configurable or does not provide supportedPaymentMethodsV2
    if (!connection.isConfigurable || !connection.provides) continue;

    for (const prov of connection.provides) {
      if (!hasSupportedPaymentMethodsV2(prov)) continue;

      for (const pm of prov.supportedPaymentMethodsV2) {
        if (existingConnectionIds.has(pm.id)) continue;

        const paymentMethodId = extractPaymentMethodId(pm.id, connection.id);

        const finalPaymentMethodId =
          PAYMENT_METHOD_MAPPINGS[paymentMethodId] || paymentMethodId;

        // Add the new payment method data
        const paymentMethodData = createGenericPaymentMethodData({
          id: pm.id,
          icon: pm.icon,
          paymentMethodId: paymentMethodId,
          name: pm.name,
        });
        if (!paymentMethodData) continue;

        paymentMethodData.$searchable =
          connectionDefinitionToSearchableString(paymentMethodData);
        result.push(paymentMethodData);
        connectionsById.set(pm.id, paymentMethodData);
        existingConnectionIds.add(pm.id);

        // Add fake payment method data if necessary
        if (!existingConnectionIds.has(paymentMethodId)) {
          const fakePaymentMethodData = createGenericPaymentMethodData({
            id: finalPaymentMethodId,
            icon: pm.icon,
            paymentMethodId: paymentMethodId,
            name: pm.name,
            modifyPaymentMethodName: true,
          });

          if (!fakePaymentMethodData) continue;

          fakePaymentMethodData.$searchable =
            connectionDefinitionToSearchableString(fakePaymentMethodData);
          result.push(fakePaymentMethodData);
          connectionsById.set(paymentMethodId, fakePaymentMethodData);
          existingConnectionIds.add(paymentMethodId);
        }
      }
    }
  }

  return { connectionList: result, connectionsById };
}
