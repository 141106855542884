const animationDurationRegular = '200ms';
const animationCurveRegular = `cubic-bezier(0.4, 0, 0.2, 1)`;
const colorDark = '#000';
const breakpointWidths = {
  mobile: '700',
  medium: '1280', // in pixels
};
const paddingRow48 = '48px';

export default {
  fontFamily: 'var(--goat-typography-body-medium-font), sans-serif',
  fontFamilyTitle: 'var(--goat-typography-body-medium-font), sans-serif',
  fontFamilyCode: "'Roboto Mono', monospace",

  fontWeights: {
    regular: 400,
    bold: 500,
  },

  fontSizes: {
    overline: { fontSize: '10px', lineHeight: '12px' },
    footnote: { fontSize: '12px', lineHeight: '20px' }, // new lineHeight: 16px
    label: { fontSize: '12px', lineHeight: '20px' }, // new lineHeight: 16px
    regular: { fontSize: '14px', lineHeight: '20px' }, // new lineHeight: 22px
    highlight: { fontSize: '16px', lineHeight: '24px' }, // new lineHeight: 24px
  },

  /*
   * GRADIENTS
   */

  /*
   * SHADOWS
   */
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  shadowCard: '0px 3px 7px -1px rgba(0, 0, 0, 0.1)', // '2px 4px 5px rgba(0, 21, 41, 0.03)',
  shadowCardPill: '2px 2px 5px -2px rgba(0, 0, 0, 0.1)',
  shadowCardFlatHover: '0px 0px 7px -1px rgba(0, 56, 255, 0.2)',
  shadowInteraction: '0px 0px 5px rgba(59, 127, 255, 0.5)', // #3374FF at 50% opacity
  shadowNegative: '0px 0px 5px rgba(243, 62, 1, 1.00)', // #F13600 at 50% opacity
  shadowToastMessage: '2px 4px 10px rgba(0, 21, 41, 0.1)',
  shadowButton: '0px 1px 6px 0px rgba(191, 191, 191, 0.8)',
  shadowButtonHover: (color) => `0px 0px 0px 4px ${color}`,
  shadowWorkflowStepHighlight: '20px 20px 30px rgb(0 21 41 / 14%)',

  /*
   * COLORS
   */
  // Branding
  colorPrimerCoral: '#FF8C78',
  colorPrimerCoralAtOpacity: (opacity) => `rgba(255, 153, 133, ${opacity})`,
  colorPrimerGreen: '#00b490',

  // Mains
  colorDark,
  colorLight: '#fff',
  colorLightAtOpacity: (opacity) => `rgba(255, 255, 255, ${opacity})`,
  colorBackground: '#F6F6F6',
  colorInteraction: '#3447F4',
  colorInteractionHover: '#0038FF',
  colorInteractionHoverBackground: '#EBF2FF',
  colorInteractionCt: '#fff',
  colorCellHover: '#fbfbfb',

  // Grays
  colorLabelIco: '#808080',
  colorPlaceholder: '#dbdbdb',
  colorBorder: '#f0f0f0',
  colorLine: '#e0e0e0',

  // Sentiments
  // Fg - foreground
  // Bg - background
  // Mg - middle-ground - used in charts or in other special circumstances
  // Ct - contrasting color - used when roles are "reverted" and Fg is used as background (example: buttons)
  colorPositiveFg: '#008D71',
  colorPositiveMg: '#00A080',
  colorPositiveBg: '#E0FAEE',
  colorPositiveCt: '#fff',

  colorNegativeFg: '#E32F41',
  colorNegativeMg: '#E94353',
  colorNegativeBg: '#FCEDEC',
  colorNegativeCt: '#fff',

  colorAttentiveFg: '#D78203',
  colorAttentiveMg: '#FD9800',
  colorAttentiveBg: '#FFF6DD',
  colorAttentiveCt: '#fff',

  colorImportantFg: '#0038FF',
  colorImportantMg: '#3447F4',
  colorImportantBg: '#D9E6FF',
  colorImportantCt: '#fff',

  colorNeutralFg: '#808080',
  colorNeutralMg: '#DBDBDB',
  colorNeutralBg: '#F0F0F0',
  colorNeutralCt: '#fff',

  // Snapshot
  colorSnapshotSalesFg: '#3447F4',
  colorSnapshotSalesBg: '#fff',
  colorSnapshotTransactionsFg: '#00b490',
  colorSnapshotTransactionsBg: '#fff',
  colorSnapshotTransactionsFailed: '#E0E0E0',

  // TODO(janjadud) Remove with rebranding
  colorSnapshotGray: '#F0EFF5',

  // Workflow
  colorWorkflowGray: '#c8c8c8',
  colorWorkflowStage: '#F2F2F2',

  // Connections
  // TODO(janjadud) Adjust with rebranding or remove
  colorProcessorFg: '#33BCB7',
  colorProcessorBg: '#B2F1E8',
  colorFraudProviderFg: '#854ECA',
  colorFraudProviderBg: '#EBD7FA',
  colorPaymentMethodFg: '#5273E0',
  colorPaymentMethodBg: '#D2E0FA',

  colorMerchantDefault: colorDark,

  /*
   * SPACING & LAYOUT
   */
  gridMarginWidth: 40, // in pixels
  gridWidthContainerMaxWidth: 1208, // in pixels (includes margins either side, actual max content width is 1128px)

  // Rounded corners
  roundedCornersSmall: '3px',
  roundedCornersRegular: '6px',
  roundedCornersPill: '45px',
  roundedFull: '9999px',

  // Dividers / Row
  dividerRow4: '4px',
  dividerRow8: '8px',
  dividerRow12: '12px',
  dividerRow24: '24px',

  // Dividers / Column
  dividerColumn12: '12px',
  dividerColumn24: '24px',

  // Paddings / Row
  paddingRow4: '4px',
  paddingRow8: '8px',
  paddingRow12: '12px',
  paddingRow16: '16px',
  paddingRow20: '20px',
  paddingRow24: '24px',
  paddingRow32: '32px',
  paddingRow36: '36px',
  paddingRow48,

  // Paddings / Column
  paddingColumn4: '4px',
  paddingColumn6: '6px',
  paddingColumn8: '8px',
  paddingColumn12: '12px',
  paddingColumn16: '16px',
  paddingColumn20: '20px',
  paddingColumn24: '24px',
  paddingColumn32: '32px',
  paddingColumn36: '36px',
  paddingColumn40: '40px',
  paddingColumn48: '48px',

  /*
   * DRAWERS
   */
  drawerContentPadding: '32px',
  drawerWidth400: '400px',
  drawerWidth600: '600px',
  drawerWidth800: '800px',
  drawerWidth1100: '1100px',

  /*
   * MODAL
   */
  modalBaseWidth: 560, // in pixels
  modalGutter: 40, // in pixels

  /*
   * AppLayout
   */
  appMenuCollapsedWidth: 72, // in pixels
  appMenuWidth: 240, // in pixels

  /*
   * INPUT
   */
  inputBaseHeight: '42px', // should be 40px, currently 42px due to InputWithTags and SelectWithTags being wrong height, should be corrected in separate PR

  /*
   * WORKFLOWS
   */
  workflowToolbarHeight: 72, // in pixels

  /*
   * ZINDEXES
   */
  zIndexAntdDrawer: 1000, // This is just a reference to what Antd has, it's not used in code
  zIndexBelowDrawer: 999,
  zIndexOverDrawer: 1001,
  zIndexModal: 1002,
  zIndexDropdownPopout: 9999,
  zIndexOverDropdownPopout: 10000,

  /*
   * ANIMATION
   */
  animationDurationRegular,
  animationCurveRegular,
  animationFramerSpringRegular: {
    type: 'spring',
    damping: 50,
    stiffness: 400,
  },

  // Breakpoints
  breakpointWidths,

  breakpoints: {
    untilMobile: `(max-width: ${+breakpointWidths.mobile - 1}px)`,
    untilMedium: `(max-width: ${+breakpointWidths.medium - 1}px)`,
    fromMedium: `(min-width: ${breakpointWidths.medium}px)`,
  },

  // Drawer
  drawerMaxWidth: `calc(100vw - ${paddingRow48})`,

  // Animation
  animationTransitionRegular: `${animationCurveRegular} ${animationDurationRegular}`,
  animationTransform: `${animationCurveRegular} 300ms`,
};
