import * as Sentry from '@sentry/react';
import { createMutation } from 'LEGACY/api';
import { queryCache } from 'react-query';
import { accountsApiUrl } from 'SRC/config';

interface Response {
  accessToken: string;
  primerAccounts: string[];
  scopes: string[];
  sessionPrimerAccountId: string;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

type CreateSessionPayload = {
  username: string | undefined;
  password: string;
};

export const useCreateSession = createMutation<CreateSessionPayload, Response>({
  api: {
    url: '/auth/login',
    apiBaseUrl: accountsApiUrl,
    authenticate: false,
    content: 'form',
    method: 'POST',
    params: { onError: { skipAll: true }, skipReloadOnUnauthorized: true },
  },
  mutation: {
    onSuccess: ({ sessionPrimerAccountId }) => {
      Sentry.setUser({ sessionPrimerAccountId });
    },
  },
  augmentMutationParams: (params) => ({ ...params, grant_type: 'password' }),
});

////////////////////////////////////////////////////////////////////////////////////////////////////

type ConfirmSessionPayload = {
  totp: string;
};

export const useConfirmSession = createMutation<
  ConfirmSessionPayload,
  Response
>({
  api: {
    url: '/auth/login/confirm',
    method: 'POST',
    content: 'json',
    apiBaseUrl: accountsApiUrl,
    authenticate: true,
    params: {
      skipReloadOnUnauthorized: true,
      skipTokenPurgingOnUnauthorized: true,
      onError: { skipAll: true },
    },
  },
  mutation: {
    onSuccess: ({ sessionPrimerAccountId }) => {
      Sentry.setUser({ sessionPrimerAccountId });
    },
  },
});

////////////////////////////////////////////////////////////////////////////////////////////////////

type SwitchSessionPayload = {
  id: string;
};

export const useSwitchSession = createMutation<SwitchSessionPayload, Response>({
  api: {
    url: ({ id }) => `/auth/login/switch/${id}`,
    method: 'POST',
    content: 'json',
    authenticate: true,
    apiBaseUrl: accountsApiUrl,
    params: {
      skipReloadOnUnauthorized: true,
      onError: { message: 'Failed to switch account' },
    },
  },
  mutation: {
    onSuccess: ({ sessionPrimerAccountId }) => {
      Sentry.setUser({ sessionPrimerAccountId });
      queryCache.clear();
    },
  },
});
