import { Badge, Icon, Sidebar, space } from '@primer-io/goat';
import {
  Activity,
  ActivitySolid,
  BookOpen,
  ChartColumn,
  ChartColumnSolid,
  CodeClose,
  CodeCloseSolid,
  CreditCard,
  CreditCardSolid,
  Database,
  DatabaseSolid,
  Home,
  HomeSolid,
  Integrations,
  IntegrationsSolid,
  Megaphone,
  MegaphoneSolid,
  ShoppingCart,
  ShoppingCartSolid,
  Tally,
  TallySolid,
  Workflow,
  WorkflowSolid,
} from '@primer-io/goat-icons';
import { FC } from 'react';
import { AppSidebarLink } from 'SRC/components/AppLayout/components/AppSidebarLink';
import { BeamerAlert } from 'SRC/components/BeamerAlert/BeamerAlert';
import { linkTo } from 'SRC/components/RoutesMain/routing';
import { useFeatureFlags } from 'SRC/hooks/useFeatureFlags';
import { useIdentity } from 'SRC/hooks/useIdentity';
import styled from 'styled-components';

const StyledBadge = styled(Badge)`
  margin-left: ${space(-1)};
`;

export const NavRoutes: FC = () => {
  const { primerEtl, reconciliation: isReconciliationEnabled } =
    useFeatureFlags();

  const { user } = useIdentity();

  return (
    <>
      <AppSidebarLink
        to='root'
        requiredPermission={null as never}
        icon={Home}
        iconActive={HomeSolid}
      >
        Home
      </AppSidebarLink>
      {user?.canAccessWorkflowsV1 ? (
        <Sidebar.Group label='Automation'>
          {user?.canAccessWorkflowsV1 ? (
            <AppSidebarLink
              to='workflowsV1'
              requiredPermission='workflows'
              icon={Workflow}
              iconActive={WorkflowSolid}
            >
              Workflows
            </AppSidebarLink>
          ) : null}
        </Sidebar.Group>
      ) : null}

      <Sidebar.Group label='Payments'>
        <AppSidebarLink
          to='payments'
          icon={CreditCard}
          iconActive={CreditCardSolid}
        >
          Payments
        </AppSidebarLink>
        <AppSidebarLink
          to='integrations'
          requiredPermission='integrations'
          icon={Integrations}
          iconActive={IntegrationsSolid}
        >
          Integrations
        </AppSidebarLink>

        <AppSidebarLink
          to='checkout'
          icon={ShoppingCart}
          iconActive={ShoppingCartSolid}
        >
          Checkout
        </AppSidebarLink>
        {isReconciliationEnabled && (
          <AppSidebarLink
            to='reconciliation'
            icon={Tally}
            iconActive={TallySolid}
          >
            Reconciliation
          </AppSidebarLink>
        )}
      </Sidebar.Group>

      <Sidebar.Group label='Observability'>
        <AppSidebarLink
          to={linkTo.observability()}
          requiredPermission='observability'
          icon={ChartColumn}
          iconActive={ChartColumnSolid}
        >
          Dashboards
        </AppSidebarLink>
        <AppSidebarLink
          to={linkTo.monitors()}
          requiredPermission='observability'
          icon={Activity}
          iconActive={ActivitySolid}
        >
          Monitors
        </AppSidebarLink>
        {primerEtl && (
          <AppSidebarLink
            to={linkTo.primerEtl()}
            requiredPermission='observability'
            icon={Database}
            iconActive={DatabaseSolid}
          >
            Primer ETL{''}
            <StyledBadge contrast='low'>BETA</StyledBadge>
          </AppSidebarLink>
        )}
      </Sidebar.Group>

      <div>
        <AppSidebarLink
          to={linkTo.developers({
            activeTab: 'apiKeys',
          })}
          requiredPermission='developers'
          icon={CodeClose}
          iconActive={CodeCloseSolid}
        >
          Developers
        </AppSidebarLink>

        {user?.canAccessWorkflowsV1 && user?.canAccessChangelogs && (
          <AppSidebarLink
            to={linkTo.changelog()}
            requiredPermission='workflows'
            icon={Megaphone}
            iconActive={MegaphoneSolid}
          >
            Changelog
            <BeamerAlert />
          </AppSidebarLink>
        )}

        <Sidebar.Item href='https://primer.io/docs/api' target='_blank'>
          <Icon as={BookOpen} />
          API reference
        </Sidebar.Item>
      </div>
    </>
  );
};
