import { useLocation, useNavigate } from 'react-router-dom';
import { pathTo } from 'SRC/components/RoutesMain/routing';
import { useIdentity } from 'SRC/hooks/useIdentity';
import { useSwitchSession } from '../api';
import { ChooseAccountCard } from '../components/login_invite/cards/ChooseAccountCard';
import { getRedirect } from '../utils';

export function LoginAccounts() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logIn } = useIdentity();

  const { accounts } = location.state ?? {};

  const [switchSession] = useSwitchSession({
    onSuccess({ accessToken, scopes }) {
      logIn(accessToken, scopes);
      navigate(getRedirect(location.search) || '/');
    },
  });

  if (!accounts) {
    navigate(pathTo.login, { replace: true });
    return null;
  }

  return (
    <ChooseAccountCard
      merchants={accounts.map((account) => ({
        id: account.id,
        name: account.companyName || '',
        background: account.companyColor,
        url: account.companyLogoUrl,
      }))}
      onChoose={(id) => switchSession({ id })}
    />
  );
}
