export const ID_PREFIXES = ['PAYPAL', 'KLARNA', 'GOCARDLESS', 'SOFORT'];

export const PROCESSOR_MAPPINGS = {
  PRIMER_PROCESSOR: 'PRIMER_TEST_',
  // Add more mappings as needed
};

export const PAYMENT_METHOD_MAPPINGS = {
  SOFORT_BANKING: 'SOFORT',
  // Add more mappings as needed
};
