import { forwardRef } from 'react';

type Identifier = number;
type Version = 'col' | 'row';
export type OfType = `${Version}${Identifier}`;

export interface SpaceProps {
  of: OfType;
  inline?: boolean;
}

const Space = forwardRef<HTMLDivElement, SpaceProps>(function Space(
  props: SpaceProps,
  ref,
) {
  return (
    <div
      ref={ref}
      style={{
        display: props.inline ? 'inline-block' : 'block',
        width: props.of.startsWith('row')
          ? `${props.of.replace('row', '')}px`
          : 'auto',
        height: props.of.startsWith('col')
          ? `${props.of.replace('col', '')}px`
          : 'auto',
        flexGrow: 0,
        flexShrink: 0,
      }}
    />
  );
});

export default Space;
