import { ReactNode } from 'react';
import {
  ErrorOption,
  FieldPath,
  FormProvider as FormProviderReactHookForm,
  SubmitErrorHandler,
  SubmitHandler,
  useForm as useFormReactHookForm,
  useFormContext as useFormContextReactHookForm,
  UseFormProps,
  UseFormReturn,
  UseFormSetError,
} from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

type FormProps = JSX.IntrinsicElements['form'] & { [key: string]: unknown };

type HookResponse<T extends FieldValues> = {
  formState: UseFormReturn<T>['formState'];
  formErrors: UseFormReturn<T>['formState']['errors'];
  getFormValues: UseFormReturn<T>['getValues'];
  renderForm: (children: ReactNode, formProps?: FormProps) => JSX.Element;
  resetForm: UseFormReturn<T>['reset'];
  setFocus: UseFormReturn<T>['setFocus'];
  setFormError: UseFormReturn<T>['setError'];
  setFormValue: UseFormReturn<T>['setValue'];
  submitForm: FormProps['onSubmit'];
  unregister: UseFormReturn<T>['unregister'];
  validateForm: UseFormReturn<T>['trigger'];
  watchForm: UseFormReturn<T>['watch'];
};

type HookParams<T extends FieldValues> = UseFormProps<T> & {
  callingSubmitManually?: boolean;
  defaultValues?: UseFormProps<T>['defaultValues'];
  onError?: SubmitErrorHandler<T>;
  onSubmit?: SubmitHandler<T>;
};

function setFormErrorFactory<T extends FieldValues>(
  setError: UseFormSetError<T>,
) {
  /**
   * @example
   * setFormError("email", "This email is already used")
   * setFormError("password", ["Too short", "Mix different characters"])
   * setFormError("myField", { ...actual RHF error-compliant payload })
   */
  return (
    fieldName: FieldPath<T>,
    err: ErrorOption | string,
    options?: { shouldFocus: boolean },
  ) => {
    if (typeof err === 'string')
      return setError(fieldName, { types: { manual: err } }, options);

    if (Array.isArray(err))
      return setError(
        fieldName,
        { types: Object.fromEntries(err.map((e, idx) => [`manual${idx}`, e])) },
        options,
      );

    return setError(fieldName, err, options);
  };
}

const defaultFormParams = {
  criteriaMode: 'all',
  mode: 'onBlur',
  reValidateMode: 'onBlur',
} as const;

export default function useForm<T extends FieldValues>({
  callingSubmitManually = false,
  defaultValues,
  onError,
  onSubmit,
  ...rest
}: HookParams<T> = {}): HookResponse<T> {
  const useFormReactHookFormPayload = useFormReactHookForm<T>({
    ...defaultFormParams,
    defaultValues,
    ...rest,
  });

  const {
    handleSubmit,
    reset,
    formState,
    setValue,
    getValues,
    trigger,
    watch,
    setError,
    unregister,
    setFocus,
  } = useFormReactHookFormPayload;

  const { errors } = formState ?? {};

  const handleSubmitBound = onSubmit
    ? handleSubmit(onSubmit, onError)
    : // would be undefined tradionally, but let's make this more developer friendly
      () =>
        console.error(
          'You tried calling `submitForm` but forgot to set `onSubmit` callback for it',
        );

  // Magic 🎉
  const renderForm = (children: ReactNode, formProps: FormProps = {}) => (
    <FormProviderReactHookForm {...useFormReactHookFormPayload}>
      <form
        onSubmit={
          onSubmit && !callingSubmitManually ? handleSubmitBound : undefined
        }
        {...formProps}
      >
        {children}
      </form>
    </FormProviderReactHookForm>
  );

  return {
    formErrors: errors,
    formState,
    getFormValues: getValues,
    renderForm,
    resetForm: reset,
    setFocus,
    setFormError: setFormErrorFactory(setError),
    setFormValue: setValue,
    submitForm: handleSubmitBound,
    unregister,
    validateForm: trigger,
    watchForm: watch,
  };
}

export function useFormContext() {
  const {
    handleSubmit,
    formState,
    watch,
    reset,
    getValues,
    setValue,
    trigger,
    setError,
    unregister,
    setFocus,
    clearErrors,
  } = useFormContextReactHookForm() ?? {};

  const { errors } = formState ?? {};

  return {
    errors,
    handleSubmit,
    formState,
    watch,
    reset,
    getValues,
    setValue,
    validateForm: trigger,
    setFormError: setFormErrorFactory(setError),
    // Aliasing
    formErrors: errors,
    resetForm: reset,
    setFormValue: setValue,
    getFormValues: getValues,
    watchForm: watch,
    unregister,
    setFocus,
    clearErrors,
  };
}

type FormContextProps = {
  children: (context: ReturnType<typeof useFormContext>) => JSX.Element;
};

export function FormContext({ children }: FormContextProps) {
  const context = useFormContext();

  return children(context);
}

export function FormProvider<T extends FieldValues>({
  children,
  ...rest
}: HookParams<T> & { children: ReactNode }) {
  const useFormReactHookFormPayload = useFormReactHookForm({
    ...defaultFormParams,
    ...rest,
  });

  return (
    <FormProviderReactHookForm {...useFormReactHookFormPayload}>
      {children}
    </FormProviderReactHookForm>
  );
}
