import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import Space from '../layout/Space';
import Text from '../typography/Text';
import Logo from '../logos/Logo';
import * as utils from '../_utils';
import Theme from '../theme';
import type { Sentiment } from 'LEGACY/_utils';
import Icon, { SupportedIconName } from './Icon';

type Size = 'micro' | 'small' | 'big';
type Style =
  | 'outline'
  | 'regular'
  | 'regularNoBorder'
  | 'naked'
  | 'regularSaturated'
  | 'regularNoBorderSaturated';

type OwnProps = {
  text?: ReactNode;
  children?: ReactNode;
  sentiment?: Sentiment;
  style?: CSSProperties;
  /**
   * @default small
   */
  size?: Size;
  /**
   * @default regular
   */
  styl?: Style;
  textStyle?: CSSProperties;

  isRemovable?: boolean;

  /**
   * ignored for size = micro
   */
  iconLeft?: ReactNode;
  textStyl?: string;

  /**
   * ignored for size = micro
   */
  iconRight?: any;
  logoLeft?: typeof Logo;
  onClick?: () => void;
  inline?: boolean;
  onRemove?: () => void;
};

export const saturatedColor = (sentiment) => {
  const sentimentColors = utils.getSentimentColors(sentiment);
  const color =
    sentiment === 'neutral' ? sentimentColors.fg : sentimentColors.mg;

  return color || sentimentColors.bg;
};

const Root = styled.div<OwnProps & { isRemovable?: boolean }>`
  box-shadow: none !important;
  ${(props) => (props.inline ? `display: inline-block;` : `display: block;`)};

  ${(props) =>
    props.size === 'big' && !props.logoLeft
      ? `padding: ${Theme.paddingColumn4} ${Theme.paddingRow16};`
      : `padding: ${Theme.paddingColumn4} ${Theme.paddingRow4};`};
  ${(props) =>
    props.size === 'small' &&
    !props.isRemovable &&
    `padding: 1px ${Theme.paddingRow8};`};
  ${(props) => props.size === 'micro' && `padding: 0px ${Theme.paddingRow4};`};
  ${(props) => props.isRemovable && `padding-left: ${Theme.paddingRow8};`}

  border-radius: ${Theme.roundedCornersRegular};
  ${(props) =>
    props.styl === 'outline'
      ? `border: 1px solid ${utils.getSentimentColors(props.sentiment).fg};`
      : ['regularNoBorder', 'naked', 'regularNoBorderSaturated'].includes(
            props.styl!,
          )
        ? 'border: 0px;'
        : props.styl === 'regularSaturated'
          ? `border: 1px solid ${saturatedColor(props.sentiment)};`
          : `border: 1px solid ${
              utils.getSentimentColors(props.sentiment).bg
            };`};

  ${(props) =>
    props.styl === 'outline'
      ? `background-color: ${Theme.colorLight};`
      : props.styl === 'naked'
        ? 'background-color: transparent;'
        : ['regularSaturated', 'regularNoBorderSaturated'].includes(props.styl!)
          ? `background-color: ${saturatedColor(props.sentiment)};`
          : `background-color: ${
              utils.getSentimentColors(props.sentiment).bg
            };`};

  ${(props) => props.styl === 'naked' && 'padding: 0px;'}

  ${(props) => props.isRemovable && `border-width: 0px;`}

  ${(props) =>
    props.onClick &&
    `
    cursor: pointer;
    
    &:hover {
      opacity: 0.8
    }
    `};
`;

const RemoveButtonStyled = styled.button.attrs({ type: 'button' })`
  border: 0;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  padding: ${Theme.paddingColumn4} ${Theme.paddingRow4};
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${Theme.paddingRow4};
  outline: none;
  border-top-right-radius: ${Theme.roundedCornersRegular};
  border-bottom-right-radius: ${Theme.roundedCornersRegular};

  &:hover,
  &:focus {
    background: ${Theme.colorInteractionHoverBackground};
  }
`;

const LogoWrapper = styled.div<{ width?: string }>`
  ${(props) => `width: ${props.width || '16px'}`};
`;

const defaultTagSize = 'small';
export function BaseTag(props: OwnProps) {
  const { children, ...otherProps } = props;
  const size = props.size ?? defaultTagSize;

  return (
    <Root {...otherProps} size={size} isRemovable={props.isRemovable}>
      {children}
    </Root>
  );
}

export default function Tag(props: OwnProps) {
  const { onRemove, ...otherProps } = props;
  const isRemovable = !!onRemove;
  const size = props.size ?? defaultTagSize;
  const space = props.size === 'big' ? 'row8' : 'row4';
  const iconContainerStyle = {
    big: { fontSize: '14px', height: '20px' },
    small: { fontSize: '14px', height: '18px' },
  }[size];

  const textStyl = (
    {
      big: 'regular',
      small: 'label',
      micro: 'footnote',
    } as const
  )[size];

  return (
    <BaseTag {...otherProps} size={size} isRemovable={isRemovable}>
      <Text
        styl={textStyl}
        sentiment={props.sentiment}
        style={{
          display: 'flex',
          alignItems: 'center',
          ...props.textStyle,
        }}
        contrast={
          props.styl === 'regularSaturated' ||
          props.styl === 'regularNoBorderSaturated'
        }
        bold={isRemovable}
      >
        {props.logoLeft && (
          <>
            <LogoWrapper>{props.logoLeft}</LogoWrapper>
            <Space of={space} />
          </>
        )}
        {props.iconLeft && size !== 'micro' && (
          <>
            <div style={iconContainerStyle}>{props.iconLeft}</div>
            {(props.text || props.iconRight) && <Space of={space} />}
          </>
        )}
        {props.text && <span>{props.text}</span>}
        {props.iconRight && size !== 'micro' && (
          <>
            <Space of={space} />
            <div style={iconContainerStyle}>{props.iconRight}</div>
          </>
        )}
        {isRemovable && size === 'small' && (
          <RemoveButtonStyled
            onClick={props.onRemove}
            data-removable-tag-button
          >
            <Icon name='X' size={16} color={Theme.colorInteraction} />
          </RemoveButtonStyled>
        )}
      </Text>
    </BaseTag>
  );
}

Tag.defaultProps = {
  styl: 'regular',
};

type RemovableTagProps = {
  text: ReactNode;
  onRemove: () => void;
  iconLeftName?: SupportedIconName;
};

export function RemovableTag(props: RemovableTagProps) {
  return (
    <Tag
      text={props.text}
      sentiment='important'
      size='small'
      iconLeft={
        props.iconLeftName && (
          <Icon
            name={props.iconLeftName}
            size={14}
            color={utils.getSentimentColors('important').fg}
          />
        )
      }
      onRemove={props.onRemove}
    />
  );
}
