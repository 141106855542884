import { appEnv } from 'SRC/config';

export type EnvironmentType = keyof typeof EnvironmentSentiment;

export enum ENVIRONMENT {
  local = 'local',
  development = 'dev',
  staging = 'staging',
  sandbox = 'sandbox',
  production = 'production',
  demo = 'demo',
}

const EnvironmentMerchantApiUrl: Record<EnvironmentType, string> = {
  local: 'http://localhost:8085',
  dev: 'https://api.dev.core.primer.io',
  staging: 'https://api.staging.core.primer.io',
  sandbox: 'https://api.sandbox.core.primer.io',
  demo: 'https://api.sandbox.core.primer.io', // Yes this is correct ;)
  production: 'https://api.production.core.primer.io',
};

const UrlToEnvironment: Record<string, EnvironmentType> = {
  'dev-dashboard.primer.io': ENVIRONMENT.development,
  'staging-dashboard.primer.io': ENVIRONMENT.staging,
  'sandbox-dashboard.primer.io': ENVIRONMENT.sandbox,
  'dashboard.primer.io': ENVIRONMENT.production,
  'demo-dashboard.primer.io': ENVIRONMENT.demo,
};

export const APP_ENV = appEnv as EnvironmentType;

export function getEnvironmentMerchantApiUrl(
  env: EnvironmentType = APP_ENV,
): string {
  return EnvironmentMerchantApiUrl[env];
}

export function getUrlEnvironment(): EnvironmentType {
  // https://developer.mozilla.org/en-US/docs/Web/API/Location
  const hostname = window.location.hostname;
  return UrlToEnvironment[hostname] ?? ENVIRONMENT.local;
}

// Sandbox's sentiment is dictated by design, others are purely subjectively mapped O:)
const EnvironmentSentiment = {
  local: 'neutral',
  dev: 'inverted',
  staging: 'positive',
  demo: 'important',
  sandbox: 'attentive',
  production: 'negative',
} as const;

// shorthand functions, using env variable and not URL env intentionally
export const isLocal = () => APP_ENV === ENVIRONMENT.local;
export const isDevelopment = () => APP_ENV === ENVIRONMENT.development;
export const isStaging = () => APP_ENV === ENVIRONMENT.staging;
export const isSandbox = () => APP_ENV === ENVIRONMENT.sandbox;
export const isProduction = () => APP_ENV === ENVIRONMENT.production;
