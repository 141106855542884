import { beamerProductId } from 'SRC/config';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMatch } from 'react-router-dom';
import { linkTo } from 'SRC/components/RoutesMain/routing';
import { useIdentity } from 'SRC/hooks/useIdentity';

const BEAMER_UPDATE_TIMEOUT = 500;
const BEAMER_DESTROY_TIMEOUT = 1000;

const baseBeamerConfig = {
  product_id: beamerProductId,
  selector: '#beamer-alert',
  top: -8,
  right: -10,
  bounce: false,
};

const changelogBeamerConfig = {
  product_id: beamerProductId,
  embed: true,
  selector: '#beamer-feed',
};

const BEAMER_URL = 'https://app.getbeamer.com/js/beamer-embed.js';

const initBeamer = (
  config: BeamerConfig,
): Promise<HTMLScriptElement | null> => {
  return new Promise((resolve, reject) => {
    window.beamer_config = config;
    const script = document.createElement('script');
    script.src = BEAMER_URL;
    script.defer = true;
    script.id = 'beamer-script';
    script.onload = () => resolve(script);
    script.onerror = () => reject(null);
    document.body.appendChild(script);
  });
};

export const useBeamer = () => {
  const match = useMatch(linkTo.changelog());
  const { user } = useIdentity();
  const isOnChangeLog = !!match;
  const isInitialising = useRef(false);

  const {
    firstName,
    lastName,
    email,
    sessionPrimerAccountId,
    canAccessChangelogs,
  } = user || {};

  const config = useMemo(() => {
    const base = isOnChangeLog ? changelogBeamerConfig : baseBeamerConfig;

    return {
      ...base,
      user_firstname: firstName,
      user_lastname: lastName,
      user_email: email,
      user_id: sessionPrimerAccountId,
    };
  }, [email, firstName, isOnChangeLog, lastName, sessionPrimerAccountId]);

  const init = useCallback(
    () =>
      new Promise((resolve) => {
        if (isInitialising.current) return;

        if (window.Beamer) {
          document.getElementById('beamerStyles')?.remove();
          document.getElementById('beamer-script')?.remove();
          window.Beamer.destroy();
        }

        if (!canAccessChangelogs || !sessionPrimerAccountId) return;
        isInitialising.current = true;

        setTimeout(() => {
          initBeamer(config).then((script) => {
            isInitialising.current = false;
            resolve(script);
          });
        }, BEAMER_DESTROY_TIMEOUT);
      }),
    [canAccessChangelogs, config, sessionPrimerAccountId],
  );

  const update = useCallback(
    async (configChanges: Partial<BeamerConfig>) => {
      if (!window.Beamer) {
        await init();
      }
      window.Beamer?.destroy();

      window.beamer_config = {
        ...config,
        ...configChanges,
      };

      setTimeout(() => {
        window.Beamer?.init();
      }, BEAMER_UPDATE_TIMEOUT);
    },
    [config, init],
  );

  const destroy = useCallback(
    () =>
      new Promise((resolve) => {
        if (!window.Beamer) {
          return resolve(true);
        }
        window.Beamer?.destroy();

        setTimeout(() => {
          document.getElementById('beamerStyles')?.remove();
          document.getElementById('beamer-script')?.remove();
          window.Beamer?.removeNotificationIframe?.();
          window.Beamer?.hideNotificationPrompt?.();
          window.Beamer = undefined;
          resolve(true);
        }, BEAMER_DESTROY_TIMEOUT * 2);
      }),
    [],
  );

  useEffect(() => {
    if (match) {
      init();
    }
  }, [init, match]);

  return {
    init,
    update,
    destroy,
  };
};
