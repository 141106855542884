import { createMutation } from 'LEGACY/api';
import { Company } from 'SRC/hooks/useIdentity';
import { accountsApiUrl } from 'SRC/config';

type GetOauthLinkPayload =
  | { initiator: 'LOGIN' }
  | { initiator: 'INVITE'; inviteCode: string }
  | { initiator: 'REGISTER' };

type GetOauthLinkResponse = {
  redirectUrl: string;
};

export const useGetOAuthLink = createMutation<
  GetOauthLinkPayload,
  GetOauthLinkResponse
>({
  api: {
    authenticate: false,
    url: '/social/auth',
    apiBaseUrl: accountsApiUrl,
    method: 'POST',
    content: 'json',
    params: { onError: { skipAll: true } },
  },
  mutation: {
    throwOnError: true,
  },
});

type LoginResponse = {
  accessToken: string;
  isInternalUser: boolean;
  scopes: string[];
  primerAccounts: Company[];
};

export const useForwardOAuthInvite = createMutation<never, LoginResponse>({
  api: {
    authenticate: false,
    apiBaseUrl: accountsApiUrl,
    url: ({ $props }) => `/social/auth/callback/invite${$props.search}`,
    method: 'GET',
    params: { onError: { skipAll: true }, skipReloadOnUnauthorized: true },
  },
  mutation: {
    throwOnError: true,
  },
});

export const useForwardOAuthLogin = createMutation<never, LoginResponse>({
  api: {
    authenticate: false,
    apiBaseUrl: accountsApiUrl,
    url: ({ $props }) => `/social/auth/callback/login${$props.search}`,
    method: 'GET',
    params: { onError: { skipAll: true }, skipReloadOnUnauthorized: true },
  },
  mutation: {
    throwOnError: true,
  },
});

export const useForwardOAuthRegister = createMutation<never, LoginResponse>({
  api: {
    authenticate: false,
    url: ({ $props }) => `/social/auth/callback/register${$props.search}`,
    method: 'GET',
    apiBaseUrl: accountsApiUrl,
    params: { onError: { skipAll: true }, skipReloadOnUnauthorized: true },
  },
  mutation: {
    throwOnError: true,
  },
});
