import { forwardRef, useRef, useState, useCallback, ReactNode } from 'react';

import {
  useFormContext,
  ErrorOption,
  Validate,
  ValidationRule,
} from 'react-hook-form';

import styled from 'styled-components';

import Input, { getGroupPositionCSS, OwnProps } from 'LEGACY/form/Input';
import FormItem from 'LEGACY/form/FormItem';
import Errors from 'LEGACY/form/Errors';
import { SupportedIconName } from 'LEGACY/components/Icon';

type GroupPosition = 'middle-row' | 'middle-col' | 'bottom' | 'right';

const Root = styled.div<
  Omit<OwnProps, 'as' | 'name'> & {
    isHovered?: boolean;
    isFocused?: boolean;
  }
>`
  width: 100%;
  ${getGroupPositionCSS}
`;

export type InputProps = {
  label?: string;
  tooltipContent?: string;
  groupPosition?: GroupPosition;

  name: string;

  /**
   * @default text
   */
  type?: string;

  isCustomPassword?: boolean;
  isCustomPasswordVisibility?: 'visible' | 'hidden';
  required?: string | ValidationRule<boolean>;
  omitRequiredLabel?: boolean;
  maxLength?: number;
  minLength?: number;
  max?: number;
  min?: number;
  pattern?: {
    regExp: RegExp;
    explanation: string;
  };
  validate?: Validate<any> | Record<string, Validate<any>>;
  valueAs?: (value: any) => any;
  valueAsNumber?: boolean;

  isSubmitting?: boolean;
  disabled?: boolean;
  readOnly?: boolean;

  // If Object, it needs to adhere to RHF raw error object
  errors?: ErrorOption | string[] | string;
  hideErrorMessages?: boolean;
  extraContent?: ReactNode;

  formItemProps?: Record<string, unknown>;

  as?: 'textarea' | 'input';
  height?: string;
  styl?: 'large';
  autoComplete?: string;
  iconName?: SupportedIconName;
  autoFocus?: boolean;
  roundedCornersSize?: number;
};

function errorToMessage(
  originalProps: InputProps,
  errorKey: string,
  errorValue?: unknown,
): string | undefined {
  // This represents custom error message specified at input level
  if (typeof errorValue === 'string') return errorValue;

  switch (errorKey) {
    case 'required':
      return 'Please fill in this field';
    case 'maxLength':
      return `This is too long, we support max ${originalProps.maxLength} characters`;
    case 'minLength':
      return `This is too short, we need at least ${
        originalProps.minLength
      } character${originalProps.minLength === 1 ? '' : 's'}`;
    case 'max':
      return `This number is too big, supported maximum is ${originalProps.max}`;
    case 'min':
      return `This number is too small, supported minimum is ${originalProps.min}`;
    case 'pattern':
      return (
        originalProps.pattern!.explanation ??
        'This does not match all requirements'
      );
    case 'range':
      return 'Maximum value must be greater than minimum value';
    default:
      return;
  }
}

type IntrinsicProps = Pick<
  JSX.IntrinsicElements['input'],
  | 'autoFocus'
  | 'defaultValue'
  | 'onBlur'
  | 'onChange'
  | 'onFocus'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'placeholder'
  | 'style'
>;

const FormInput = forwardRef((props: InputProps & IntrinsicProps, ref) => {
  const {
    label,
    tooltipContent,
    name,
    type = 'text',
    required,
    omitRequiredLabel = false,
    maxLength,
    minLength,
    max,
    min,
    pattern,
    validate,
    errors,
    hideErrorMessages,
    isSubmitting,
    readOnly,
    disabled,
    extraContent,
    formItemProps = {},
    valueAs,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    valueAsNumber,
    onChange,
    as = 'input',
    height,
    ...otherProps
  } = props;

  const { register } = useFormContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(otherProps.autoFocus ?? false);
  const [isHovered, setIsHovered] = useState(false);

  const onFocusAdjusted = useCallback(
    (evt) => {
      setIsFocused(true);
      onFocus?.(evt);
    },
    [onFocus, setIsFocused],
  );

  const onMouseEnterAdjusted = useCallback(
    (evt) => {
      setIsHovered(true);
      onMouseEnter?.(evt);
    },
    [onMouseEnter, setIsHovered],
  );
  const onMouseLeaveAdjusted = useCallback(
    (evt) => {
      setIsHovered(false);
      onMouseLeave?.(evt);
    },
    [onMouseLeave, setIsHovered],
  );

  const errorMessages = !errors
    ? []
    : Array.isArray(errors)
      ? [...errors]
      : typeof errors === 'string'
        ? [errors]
        : (Object.entries(errors.types ?? {})
            .map(([key, value]) => errorToMessage(props, key, value))
            .filter(Boolean) as string[]);

  const readOnlyMerged = isSubmitting ?? readOnly;

  const propsFromRegister = register(name, {
    required,
    maxLength,
    minLength,
    max,
    min,
    pattern: pattern?.regExp ?? undefined,
    validate,
    valueAsNumber,
    setValueAs: valueAs,
  });

  const onChangeAdjusted = (e) => {
    e.target.value = onChange?.(e.target.value) ?? e.target.value;
    return propsFromRegister.onChange(e);
  };

  const onBlurAdjusted = useCallback(
    (evt) => {
      setIsFocused(false);
      onBlur?.(evt);
      propsFromRegister.onBlur(evt);
    },
    [onBlur, setIsFocused, propsFromRegister],
  );

  const InputComponent = (
    <Input
      {...otherProps}
      {...propsFromRegister}
      ref={(_ref) => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(_ref);
          } else {
            ref.current = _ref;
          }
        }
        propsFromRegister.ref(_ref);
        (inputRef as any).current = _ref;
      }}
      id={name}
      type={type}
      readOnly={readOnlyMerged}
      hasErrors={errorMessages.length > 0}
      disabled={disabled}
      onFocus={onFocusAdjusted}
      onBlur={onBlurAdjusted}
      onChange={onChangeAdjusted}
      onMouseEnter={onMouseEnterAdjusted}
      onMouseLeave={onMouseLeaveAdjusted}
      maxLength={maxLength}
      minLength={minLength}
      isWrapped
      as={as}
      height={height}
    />
  );

  const ErrorComponent = !hideErrorMessages && (
    <Errors errorMessages={errorMessages} />
  );

  if (label || tooltipContent) {
    return (
      <FormItem
        label={label ?? ''}
        htmlFor={name}
        tooltipContent={tooltipContent}
        required={omitRequiredLabel ? undefined : required}
        disabled={disabled}
        readOnly={readOnlyMerged}
        {...formItemProps}
      >
        {InputComponent}
        {ErrorComponent}
        {extraContent}
      </FormItem>
    );
  }

  return (
    <Root
      isFocused={isFocused}
      isHovered={isHovered}
      groupPosition={otherProps.groupPosition}
    >
      {InputComponent}
      {ErrorComponent}
      {extraContent}
    </Root>
  );
});

export default FormInput;
