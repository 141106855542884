import Button from 'LEGACY/components/Button';

import useForm, { FormInput } from 'LEGACY/hooks/useForm';
import Space from 'LEGACY/layout/Space';
import Errors from 'LEGACY/form/Errors';

export type OwnProps = {
  onSubmit?: (values: any) => void;
  externalError?: string;
  loading?: boolean;
};

export default function ForgotPassword(props: OwnProps) {
  const { onSubmit, externalError, loading = false } = props;

  const { renderForm, formErrors, formState } = useForm({
    onSubmit,
    defaultValues: { username: '' },
  });
  const { isSubmitting } = formState;

  return renderForm(
    <>
      <FormInput
        label='Email'
        name='username'
        errors={formErrors.username}
        required='An email address is required'
        omitRequiredLabel
        isSubmitting={loading || isSubmitting}
        styl='large'
        autoComplete='username'
        formItemProps={{ labelFwdProps: { styl: 'regular' } }}
        data-testid='input-username'
        autoFocus
      />
      <Space of='col32' />
      <Button
        type='submit'
        disabled={isSubmitting}
        loading={loading || isSubmitting}
        grow
        size='large'
        sentiment='branding'
        data-testid='button-submit'
        rightIcon='ArrowRight'
      >
        Request link
      </Button>
      {externalError && <Space of='col8' />}
      <Errors
        errorMessages={externalError ? [externalError] : undefined}
        data-testid='component-Errors'
      />
    </>,
  );
}
