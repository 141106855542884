import { identity as _identity, noop as _noop, omit as _omit } from 'lodash-es';
import { MutationFunction, queryCache, useMutation } from 'react-query';
import { KeyFn, mergeCreateApiRequestOptions } from './common';
import createApiRequest, { CreateApiRequestOptions } from './createApiRequest';

export type MutationConfig<Response = any> = {
  onMutate?: (...args: any[]) => void;
  onSuccess?: (data: Response, ...args: any[]) => void;
  onError?: (...args: any[]) => void;
  onSettled?: (...args: any[]) => void;
  props?: Record<PropertyKey, any>;
  throwOnError?: boolean;
};

export const defaultMutationConfig: MutationConfig<unknown> = {
  onMutate: _noop,
  onSuccess: _noop,
  onError: _noop,
  onSettled: _noop,
  props: {},
  throwOnError: false,
};

/**
 * Merges mutation configs and makes sure callback are called as specified on each level
 */
function mergeMutationConfigs<T>(
  definitionMutationConfig: MutationConfig<T> = {},
  usageMutationConfig: MutationConfig<T> = {},
) {
  const mergedMutationConfig = {
    ...defaultMutationConfig,
    ...definitionMutationConfig,
    ...usageMutationConfig,
  };

  // Merge onMutate callback so later specified ones won't overwrite sooned defined ones
  mergedMutationConfig.onMutate = (vars) => {
    defaultMutationConfig.onMutate && defaultMutationConfig.onMutate(vars);
    // prettier-ignore
    definitionMutationConfig.onMutate && definitionMutationConfig.onMutate(vars);
    usageMutationConfig.onMutate && usageMutationConfig.onMutate(vars);
  };

  // Merge onError callback so later specified ones won't overwrite sooned defined ones
  mergedMutationConfig.onError = (e, vars, onMutateValue) => {
    // prettier-ignore
    defaultMutationConfig.onError && defaultMutationConfig.onError(e, vars, onMutateValue);
    // prettier-ignore
    definitionMutationConfig.onError && definitionMutationConfig.onError(e, vars, onMutateValue);
    // prettier-ignore
    usageMutationConfig.onError && usageMutationConfig.onError(e, vars, onMutateValue);
  };

  // Merge onSettled callback so later specified ones won't overwrite sooned defined ones
  mergedMutationConfig.onSettled = (data, e, vars, onMutateValue) => {
    // prettier-ignore
    defaultMutationConfig.onSettled && defaultMutationConfig.onSettled(data, e, vars, onMutateValue);
    // prettier-ignore
    definitionMutationConfig.onSettled && definitionMutationConfig.onSettled(data, e, vars, onMutateValue);
    // prettier-ignore
    usageMutationConfig.onSettled && usageMutationConfig.onSettled(data, e, vars, onMutateValue);
  };

  // Merge onSuccess callback so later specified ones won't overwrite sooned defined ones
  mergedMutationConfig.onSuccess = (data, vars) => {
    // prettier-ignore
    defaultMutationConfig.onSuccess && defaultMutationConfig.onSuccess(data, vars);
    // prettier-ignore
    definitionMutationConfig.onSuccess && definitionMutationConfig.onSuccess(data, vars);
    usageMutationConfig.onSuccess && usageMutationConfig.onSuccess(data, vars);
  };

  return mergedMutationConfig;
}

type Params = Record<PropertyKey, any> & {
  $props: Record<PropertyKey, any>;
};

type BuildMutationOptions<Response> = {
  /**
   * Allows to augment what is passed to the `mutate` fn before calling it.
   *
   * Available args are:
   * - params: whatever was passed to `mutate` fn in the 1st place
   * - props: props are injected into the `params` as `$props`
   */
  augmentMutationParams?: (params: Params) => Params;

  /**
   * Keys specified here are automatically refetched in onSuccess callback
   */
  keysToRefetch?: Array<KeyFn | string>;

  /**
   * Keys specified here are removed from cache in onSuccess callback
   */
  keysToRemove?: Array<KeyFn | string>;

  /**
   * You can pass defaults for mutation how to construct the API request, these usually are `url`, `method`, `content` and `authenticate`
   */
  api: CreateApiRequestOptions<Params>;

  /**
   * You can pass defaults for actual mutation behaviour
   */
  mutation?: MutationConfig<Response>;
};

/** ************************************************************************************************
 * Main fn of the file
 **************************************************************************************************/
export function createMutation<
  Payload = Record<PropertyKey, any>,
  Response = any,
>({
  augmentMutationParams = _identity,
  keysToRefetch,
  keysToRemove,
  api: definitionCreateApiRequestOptions,
  mutation: definitionMutationConfig = {},
}: BuildMutationOptions<Response>) {
  /*
   * CONSTRUCTING THE MUTATION FN
   */
  return (
    usageMutationConfig: MutationConfig<Response> = {},
    usageCreateApiRequestOptions: Partial<CreateApiRequestOptions<Params>> = {},
  ) => {
    // MERGE MUTATION CONFIG
    const mergedMutationConfig = mergeMutationConfigs(
      definitionMutationConfig,
      usageMutationConfig,
    );

    // MERGE API REQ OPTIONS
    const mergedCreateApiRequestOptions = mergeCreateApiRequestOptions(
      definitionCreateApiRequestOptions,
      usageCreateApiRequestOptions,
    );

    const mergedPayloadFn = mergedCreateApiRequestOptions.payload;
    mergedCreateApiRequestOptions.payload = (payload) => {
      const processedPayload = mergedPayloadFn
        ? mergedPayloadFn(payload)
        : payload;
      return _omit(processedPayload, '$props');
    };

    // The most common case is to refetch queries after a successful mutation (which usually creates/edits/deletes smth) so that the screen shows
    // most updated info. For this, you can specify `keysToRefetch` in buildOptions and these keys will be automatically added to `onSuccess` so you don't to manually rememeber to
    // hijack onSuccess with query refetches and also call any potential original onSuccess passed
    if (keysToRefetch || keysToRemove) {
      const mergedOnSuccess = mergedMutationConfig.onSuccess;
      // Augment merged `onSuccess` once more
      mergedMutationConfig.onSuccess = (...args) => {
        const functionKeyArgs = {
          // Provide expanded `props` & `queryParams` if they were specified
          // Expanding here, because key functions are used by queries as well and
          // writing key fns listen to both direct parameters and `props` as well would be too complicated
          ...(mergedMutationConfig.props ?? {}),
          queryParams:
            (mergedCreateApiRequestOptions.queryParams as Record<
              string,
              any
            >) ?? undefined,
        };
        keysToRefetch?.forEach((keyToRefetch) => {
          if (typeof keyToRefetch === 'string') {
            queryCache.invalidateQueries(keyToRefetch);
          } else {
            queryCache.invalidateQueries(keyToRefetch(functionKeyArgs));
          }
        });

        keysToRemove?.forEach((keyToRemove) => {
          if (typeof keyToRemove === 'string') {
            queryCache.removeQueries(keyToRemove);
          } else {
            queryCache.removeQueries(keyToRemove(functionKeyArgs));
          }
        });

        // Call the originally merged `onSuccess`
        mergedOnSuccess?.(...args);
      };
    }

    const [mutation, response] = useMutation<Response, unknown, Payload>(
      createApiRequest(mergedCreateApiRequestOptions) as MutationFunction<any>,
      mergedMutationConfig,
    );

    const mutate = (
      params?: Payload,
      mutationConfig?: MutationConfig<Response>,
    ) =>
      mutation(
        augmentMutationParams({
          ...params,
          $props: {
            ...mergedMutationConfig.props,
            ...(params as Params | undefined)?.$props,
          },
        }),
        mutationConfig,
      );

    return [mutate, response] as const;
  };
}
