import { color, space, typography } from '@primer-io/goat';
import styled from 'styled-components';

const Root = styled.div`
  ${typography('label')};
  color: ${color('gray-600')};
  display: flex;
  gap: ${space(4)};
  place-items: center;

  &:before,
  &:after {
    background-color: ${color('gray-600')};
    content: '';
    display: block;
    height: 1px;
    width: 100%;
  }
`;

export const OrSeparator = () => <Root>Or</Root>;
