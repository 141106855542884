import { useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import Tippy, { TippyProps } from '@tippyjs/react/headless';
import { Options as PopperOptions, Placement } from '@popperjs/core';
import maxSize from 'popper-max-size-modifier';
import { mapTippyPlacementToTransformOrigin } from 'LEGACY/_utils';

const animatePopout = {
  scale: {
    from: 0.95,
    to: 1,
  },
  opacity: {
    from: 0,
    to: 1,
  },
  timingFunction: {
    in: 'cubic-bezier(0, 0, 0.2, 1)',
    out: 'cubic-bezier(0.4, 0, 1, 1)',
  },
  duration: {
    in: 100,
    out: 75,
  },
};

const TriggerWrapperStyled = styled.div`
  width: fit-content;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

const DropdownWrapperStyled = styled.div<{
  animation: boolean;
  transformOrigin: Placement;
}>`
  width: 100%;

  ${(p) =>
    p.animation &&
    `
      transition-timing-function: ${animatePopout.timingFunction.in};
      transition-property: all;
      transition-duration: ${animatePopout.duration.out}ms;
      opacity: ${animatePopout.opacity.from};
      transform: scale(${animatePopout.scale.from});
      transform-origin: ${p.transformOrigin};
  `}
`;

/**
 * The strategy to use when the dropdown would exceed the container.
 *
 * Flip means the dropdown would vertically flip on the other side.
 *
 * Scroll means the dropdown would not flip but instead have a scrollbar.
 * This forces fixedPositioning.
 *
 * None means the dropdown would expand the size of the parent.
 *
 * @default none
 */
export type OverflowStrategy = 'flip' | 'scroll' | 'none';
export interface Props {
  triggerElement: React.ReactNode;
  children: React.ReactNode;
  popoutOptions?: TippyProps;
  uncontrolled?: boolean;
  /**
   * @default true
   */
  hideOnClick?: boolean;
  triggerWrapperStyles?: React.CSSProperties;
  /**
   * @default true
   */
  animation?: boolean;
  fixedPositioning?: boolean;
  matchTriggerWidth?: boolean;
  /**
   * @default none
   */
  overflow?: OverflowStrategy;
}

const DropdownPopout = (props: Props) => {
  const {
    overflow = 'none',
    popoutOptions: popoutOptionsProp,
    triggerElement,
    children,
    uncontrolled,
    hideOnClick = props.popoutOptions?.hideOnClick ?? true,
    triggerWrapperStyles,
    animation = true,
    fixedPositioning: fixedPositioningProp,
    matchTriggerWidth,
  } = props;

  const fixedPositioning = fixedPositioningProp ?? overflow === 'scroll';

  const popoutOptions = {
    ...popoutOptionsProp,
    ...(overflow === 'flip'
      ? {
          popperOptions: {
            modifiers: [
              {
                name: 'flip',
                enabled: true,
              },
              {
                name: 'preventOverflow',
                options: {
                  altAxis: true,
                  tether: false,
                },
              },
            ],
          },
        }
      : {}),
  };

  const [tippyVisible, setTippyVisible] = useState(false);
  const [popperHeight, setPopperHeight] = useState<number | undefined>(
    undefined,
  );
  const showDropdown = () => setTippyVisible(true);
  const hideDropdown = () => setTippyVisible(false);

  const handleClickOutside = (instance, event) => {
    if (event.target && !instance.reference?.contains(event.target)) {
      setTippyVisible(false);
    }
  };

  const defaultPopoutOptions = {
    visible: !uncontrolled ? tippyVisible : undefined,
    interactive: true,
    onClickOutside: handleClickOutside,
    placement: 'bottom-end' as Placement,
    animation: animation,
    maxWidth: 'none',
    ...(animation && {
      onMount(instance) {
        const box = instance.popper.firstElementChild;
        requestAnimationFrame(() => {
          box.style.opacity = animatePopout.opacity.to;
          box.style.transform = `scale(${animatePopout.scale.to})`;
        });
      },
      onHide(instance) {
        const box = instance.popper.firstElementChild;
        requestAnimationFrame(() => {
          box.style.transitionTimingFunction = animatePopout.timingFunction.out;
          box.style.transitionDuration = `${animatePopout.duration.out}ms`;
          box.style.opacity = animatePopout.opacity.from;
          box.style.transform = `scale(${animatePopout.scale.from})`;
          setTimeout(instance.unmount, animatePopout.duration.out);
        });
      },
    }),
  };

  // The idea here is that everything in the `defaultPopoutOptions` above,
  // can be overridden by what the user passes to `popoutOptions`.
  const mergedPopoutOptions = {
    ...defaultPopoutOptions,
    ...popoutOptions,
  };

  // We are technically using Tippy in it's 'controlled' mode, which impacts
  // the behaviour in small ways. This component has a prop to toggle Tippy into
  // it's default 'uncontrolled' mode.
  const isInUncontrolledMode =
    typeof mergedPopoutOptions.visible === 'undefined';
  // By default, this component controls the visibility of Tippy, but this can be
  // overriden (controlled) by the parent via the `visible` prop.
  const isParentControllingVisibility =
    typeof popoutOptions?.visible !== 'undefined';

  // Handles matching the dropdown width to the trigger width
  const sameWidth = {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
      state.styles.popper.width = `${state.rects.reference.width}px`;
    },
    effect: ({ state }) => {
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    },
  };

  // When using the `fixedPositioning` prop, it's possible for the dropdown to
  // overflow the viewport. This plugin ensures the overall height of the
  // dropdown element stays within the viewport bounds, you can then use
  // CSS overflow to make your content scrollable and therefor always viewable
  // by some means.
  const applyMaxSize = {
    name: 'applyMaxSize',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['maxSize'],
    fn({ state }) {
      const { height } = state.modifiersData.maxSize;

      if (!popperHeight) {
        setPopperHeight(
          state.elements.popper.firstElementChild.getBoundingClientRect()
            .height,
        );
      }

      if (fixedPositioning) {
        if (popperHeight && height < popperHeight) {
          state.styles.popper.maxHeight = `${height}px`;
          state.elements.popper.firstChild.style.height = `${height}px`;
        } else {
          state.styles.popper.maxHeight = `fit-content`;
          state.elements.popper.firstChild.style.height = `fit-content`;
        }
      }
    },
  };

  const defaultPopperOptions: PopperOptions = {
    strategy: fixedPositioning ? 'fixed' : 'absolute',
    placement: mergedPopoutOptions.placement,
    // @ts-ignore
    modifiers: [
      {
        name: 'flip',
        enabled: false,
      },
      {
        name: 'preventOverflow',
        enabled: false,
      },
      matchTriggerWidth && sameWidth,
      fixedPositioning && maxSize,
      fixedPositioning && applyMaxSize,
      // Popper automatically tries to process any objects in this array,
      // including undefined values / empty objects, so needed a way,
      // to have these objects added conditionally whilst leaving no remnants.
    ].filter(Boolean),
  };

  return (
    <Tippy
      render={(attrs) => (
        <DropdownWrapperStyled
          animation={animation}
          // @ts-expect-error
          transformOrigin={mapTippyPlacementToTransformOrigin(
            mergedPopoutOptions.placement,
          )}
          onClick={hideOnClick && tippyVisible ? hideDropdown : undefined}
          tabIndex={-1}
          {...attrs}
        >
          {children}
        </DropdownWrapperStyled>
      )}
      {...(!popoutOptions?.popperOptions && {
        popperOptions: defaultPopperOptions,
      })}
      {...mergedPopoutOptions}
    >
      <TriggerWrapperStyled
        onClick={
          isInUncontrolledMode || isParentControllingVisibility
            ? undefined
            : tippyVisible
              ? hideDropdown
              : showDropdown
        }
        style={triggerWrapperStyles}
      >
        {triggerElement}
      </TriggerWrapperStyled>
    </Tippy>
  );
};

export default DropdownPopout;
