import { P, cx } from '@primer-io/goat';
import { ReactNode } from 'react';
import styles from './EmptyPage.module.scss';

interface EmptyPageProps {
  image: string;
  title: string;
  extraText?: ReactNode | ReactNode[];
  cta?: ReactNode;
  centerVertically?: boolean;
}

export const EmptyPage = ({
  title,
  image,
  extraText,
  cta,
  centerVertically,
}: EmptyPageProps) => (
  <div
    className={cx(styles.root, { [styles.centerVertically]: centerVertically })}
  >
    <div className={styles.content}>
      <img src={image} alt='' className={styles.image} />
      <P type='title-large'>{title}</P>
      {Array.isArray(extraText) ? (
        extraText.map((text, index) => (
          <P
            key={index}
            type='body-medium'
            color='gray-600'
            className={styles.extraText}
          >
            {text}
          </P>
        ))
      ) : (
        <P type='body-medium' color='gray-600' className={styles.extraText}>
          {extraText}
        </P>
      )}
    </div>
    {cta}
  </div>
);
