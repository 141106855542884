export function validatePassword(
  email: string,
  password: string,
): string | boolean | undefined {
  if (!password) return;

  if (password === email)
    return "Password can't be the same as your email address, please choose a different one";

  if (!/^(.*[^a-zA-Z].*)$/.test(password) || !/^(.*[^0-9].*)$/.test(password))
    return "Please mix letters and numbers, don't use just one type of characters";

  return true;
}
