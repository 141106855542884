import { space } from '@primer-io/goat';
import EmptyState from 'LEGACY/components/EmptyState';
import { toast } from 'SRC/components/Toast';
import Errors from 'LEGACY/form/Errors';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIdentity } from 'SRC/hooks/useIdentity';
import styled from 'styled-components';
import { pathTo } from 'SRC/components/RoutesMain/routing';
import { useForwardSso } from '../api/auth';

const Root = styled.div`
  padding: ${space(6)};
`;

export function SSO() {
  const { action } = useParams<{ action: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const { logIn } = useIdentity();

  const [forwardSso] = useForwardSso({
    props: { action, search },
    onError: (err) => setError(err.message),
    onSuccess({ accessToken, primerAccounts, scopes }) {
      if (action === 'invite') {
        const company = primerAccounts.find((a) => a.companyName);
        const { companyName = 'Primer' } = company ?? {};
        toast.positive({
          description: `Invite accepted. Welcome to ${companyName}!`,
        });
      }

      logIn(accessToken, scopes);
      navigate(pathTo.root);
    },
  });

  useEffect(() => void forwardSso(), []);

  if (error)
    return <Errors data-testid='component-Errors' errorMessages={[error]} />;

  return (
    <Root>
      <EmptyState bare title='Processing' />
    </Root>
  );
}
