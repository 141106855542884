import { ReactNode } from 'react';
import styled from 'styled-components';

const RootStyled = styled.main`
  width: 100%;
  overflow: auto;
  padding-bottom: 80px;
`;

interface PageWrapperProps {
  children: ReactNode;
}

const PageWrapper = (props: PageWrapperProps) => {
  const { children, ...otherProps } = props;
  return (
    <RootStyled id='component-PageLayout--content' {...otherProps}>
      {props.children}
    </RootStyled>
  );
};

export default PageWrapper;
