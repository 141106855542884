import { useRef, useState, useEffect } from 'react';
import * as React from 'react';
import { useCombobox } from 'downshift';
import { Placement } from '@popperjs/core';
import styled from 'styled-components';
import Theme from 'LEGACY/theme';
import Icon from 'LEGACY/components/Icon';
import { buttonReset } from 'LEGACY/styles/resets';
import { textRegular } from 'LEGACY/styles/typography';
import TextTooltip from 'LEGACY/TextTooltip';

import DropdownPopout, {
  OverflowStrategy,
} from 'LEGACY/components/Dropdown/DropdownPopout/DropdownPopout';
import { color } from '@primer-io/goat';

const EvenTextOverflow = styled.div<{ columns: number }>`
  display: grid;
  /**
  * We don't want even columns all the time, we only want the text-overflow to be even, 
  * hence we use auto and not percentages.
  */
  grid-template-columns: repeat(${(props) => props.columns}, auto);
`;

const ListItemHeight = 48; // in pixels

// Same as groupPosition in Input.js
type GroupPosition =
  | 'left'
  | 'right'
  | 'top'
  | 'bottom'
  | 'middle-row'
  | 'middle-col';

const DropdownTriggerStyled = styled.button.attrs({ type: 'button' })<{
  groupPosition?: GroupPosition;
  hasErrors?: boolean;
  disabled?: boolean;
}>`
  ${buttonReset}

  height: ${Theme.inputBaseHeight};
  display: flex;
  align-items: center;
  background: ${Theme.colorLight};
  border: solid 1px ${Theme.colorPlaceholder};
  border-radius: ${Theme.roundedCornersRegular};
  width: 100%;
  color: ${Theme.colorInteraction};
  padding-left: ${Theme.paddingColumn12};
  cursor: pointer;
  padding-right: ${Theme.paddingColumn36};

  ${(props) =>
    props.hasErrors &&
    `
    border-color: ${Theme.colorNegativeFg} !important;
  `}

  ${(props) => {
    if (!props.groupPosition) return '';
    if (
      props.groupPosition === 'middle-row' ||
      props.groupPosition === 'middle-col'
    )
      return `border-radius: 0px;`;
    if (props.groupPosition === 'left')
      return `border-radius: ${Theme.roundedCornersRegular} 0px 0px ${Theme.roundedCornersRegular};`;
    if (props.groupPosition === 'right')
      return `border-radius: 0px ${Theme.roundedCornersRegular} ${Theme.roundedCornersRegular} 0px;`;
    if (props.groupPosition === 'top')
      return `border-radius: ${Theme.roundedCornersRegular} ${Theme.roundedCornersRegular} 0px 0px;`;
    if (props.groupPosition === 'bottom')
      return `border-radius: 0px 0px ${Theme.roundedCornersRegular} ${Theme.roundedCornersRegular};`;
  }}

  &:focus {
    border-color: ${Theme.colorInteraction};
  }

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      pointer-events: none;
      background: ${color('gray-200')};
      color: ${Theme.colorLabelIco};
      border-color: ${color('gray-300')};
    `}
`;

const RootStyled = styled.div<{ disabled?: boolean }>`
  width: 100%;
  position: relative;

  &:focus-within {
    ${DropdownTriggerStyled} {
      border-color: ${Theme.colorInteraction};
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      pointer-events: none;
      background: ${color('gray-200')};
      color: ${Theme.colorLabelIco};
      border-color: ${color('gray-300')};
    `}
`;

const DropdownWrapperStyled = styled.div<{
  inputHeight?: number;
  maxListItems: number;
  $width?: number | string;
}>`
  background: ${Theme.colorLight};
  width: ${(p) =>
    isNaN(p.$width as number) ? p.$width ?? '100%' : p.$width + 'px'};
  border-radius: ${Theme.roundedCornersSmall};
  box-shadow: ${Theme.shadowCard};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: ${(p) =>
    p.inputHeight
      ? p.inputHeight + ListItemHeight * p.maxListItems
      : ListItemHeight * p.maxListItems}px;
`;

const ListItemStyled = styled.div<{ highlighted: boolean }>`
  padding: 0 ${Theme.paddingColumn12};
  cursor: pointer;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  height: ${ListItemHeight}px;

  ${(p) =>
    p.highlighted &&
    `
    font-weight: ${Theme.fontWeights.bold};
    background-color: ${Theme.colorInteractionHoverBackground};
  `}

  &:hover {
    font-weight: ${Theme.fontWeights.bold};
    background-color: ${Theme.colorInteractionHoverBackground};
  }
`;

const OptionLabelTextStyled = styled.div`
  ${textRegular}
  color: ${Theme.colorDark} !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const OptionSecondaryLabelTextStyled = styled.div`
  ${textRegular}
  color: ${Theme.colorLabelIco};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${OptionLabelTextStyled} + & {
    margin-left: ${Theme.paddingColumn8};
  }
`;

const SelectedItemLabelTextStyled = styled(OptionLabelTextStyled)`
  font-weight: ${Theme.fontWeights.bold};
  color: ${Theme.colorInteraction};
`;

const SelectedItemSecondaryLabelTextStyled = styled(
  OptionSecondaryLabelTextStyled,
)`
  font-weight: ${Theme.fontWeights.bold};
`;

export const TriggerPlaceholderTextStyled = styled.div`
  ${textRegular}
  color: ${Theme.colorLabelIco};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const IconWrapperStyled = styled.div`
  position: absolute;
  top: 0;
  right: ${Theme.paddingColumn12};
  bottom: 0;
  display: flex;
  align-items: center;
  pointer-events: none;
`;

const NoResultsTextStyled = styled.div`
  ${textRegular}
  padding: 12px;
  color: ${Theme.colorLabelIco};
`;

// TODO: Replace with TextField component
const InputNativeStyled = styled.input<{ disabled: boolean }>`
  ${textRegular}
  position: relative;
  width: 100%;
  min-width: 0;
  appearance: none;
  border-radius: 0;
  padding: 7px 36px 7px 12px;
  border: none;
  outline: 0;
  background-color: transparent;
  color: ${(props) => (props.disabled ? Theme.colorLabelIco : Theme.colorDark)};

  &::placeholder {
    font-weight: ${Theme.fontWeights.bold};
    color: ${Theme.colorInteraction};
  }
`;

const TypeaheadWrapperStyled = styled.div`
  position: relative;
  border-bottom: solid 1px ${Theme.colorBorder};
`;

const ListWrapperStyled = styled.div`
  flex: 1 1 0%;
  overflow-y: auto;
  overscroll-behavior: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: ${Theme.roundedFull};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export interface SelectWithSearchOption {
  label: string;
  secondaryLabel?: string;
  icon?: React.ReactNode;
  value: string;
}

export interface SelectWithSearchPreviewLabelItems {
  label?: boolean;
  secondaryLabel?: boolean;
}

export type SelectWithSearchLabelOptions = 'primary' | 'secondary';

export interface Props {
  customTriggerElement?: (props: any, ref: any) => React.ReactNode;
  overflow?: OverflowStrategy;
  options: SelectWithSearchOption[];
  value: string;
  onChange: (item) => void;
  onBlur?: () => void;
  triggerPlaceholder?: string;
  disableTooltip?: boolean;
  selectedItemLabel?: SelectWithSearchLabelOptions;

  /**
   * @default true
   */
  dropdownMatchesTriggerWidth?: boolean;

  /**
   * @default bottom
   */
  dropdownPlacement?: Placement;
  dropdownOffset?: [number, number];

  /**
   * @default false
   */
  dropdownUsesFixedPositioning?: boolean;
  groupPosition?: GroupPosition;
  dropdownContainerWidth?: number | string;

  hasErrors?: boolean;

  disabled?: boolean;
}

const emptyOption: SelectWithSearchOption = {
  label: '',
  secondaryLabel: '',
  value: '',
  icon: '',
};

const SelectWithSearch = React.forwardRef((props: Props, ref) => {
  const {
    customTriggerElement,
    options,
    value,
    onChange,
    onBlur,
    triggerPlaceholder,
    dropdownMatchesTriggerWidth = true,
    dropdownPlacement = 'bottom',
    dropdownOffset,
    dropdownUsesFixedPositioning = false,
    selectedItemLabel,
    groupPosition,
    hasErrors,
    dropdownContainerWidth,
    overflow = 'none',
    disableTooltip,
    disabled,
  } = props;

  const typeaheadInputRef = useRef<HTMLInputElement>(null);
  const dropdownWrapperRef = useRef<HTMLDivElement | null>(null);
  const [typeaheadInputHeight, setTypeaheadInputHeight] = useState<
    number | undefined
  >();

  const [inputValue, setInputValue] = useState<string | undefined>('');

  const inputFilter = (item) => {
    return (
      !inputValue ||
      item.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      item.secondaryLabel?.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filteredOptions = options.filter(inputFilter);

  const selectedOption =
    options.find((item) => {
      return item.value === value;
    }) ?? emptyOption;

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    selectedItem,
    selectItem,
    closeMenu,
    highlightedIndex,
  } = useCombobox({
    items: filteredOptions,
    selectedItem: selectedOption,
    itemToString: () => '',

    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem?.value);
      closeMenu();
    },
    onIsOpenChange: ({ isOpen }) => {
      if (!isOpen) {
        onBlur?.();
      }
      if (isOpen && typeaheadInputRef.current) {
        typeaheadInputRef?.current.focus({ preventScroll: true });
      }
    },
  });

  const triggerPlaceholderText = triggerPlaceholder || 'Please select';

  useEffect(() => {
    if (typeaheadInputRef.current) {
      setTypeaheadInputHeight(
        typeaheadInputRef.current.getBoundingClientRect().height,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeaheadInputRef.current]);

  return (
    <RootStyled disabled={disabled}>
      <div>
        <DropdownPopout
          overflow={overflow}
          triggerElement={
            customTriggerElement ? (
              customTriggerElement(getToggleButtonProps(), ref)
            ) : (
              <DropdownTriggerStyled
                {...getToggleButtonProps()}
                ref={ref}
                tabIndex={0}
                groupPosition={groupPosition}
                hasErrors={hasErrors}
                disabled={disabled}
              >
                {selectedItem?.value ? (
                  <>
                    {selectedItemLabel ? (
                      <EvenTextOverflow columns={2}>
                        {selectedItemLabel === 'primary' ? (
                          <TextTooltip
                            title={selectedItem.label}
                            disabled={disableTooltip}
                          >
                            <SelectedItemLabelTextStyled>
                              {selectedItem.label}
                            </SelectedItemLabelTextStyled>
                          </TextTooltip>
                        ) : (
                          <>
                            <TextTooltip
                              title={selectedItem.secondaryLabel ?? ''}
                              disabled={disableTooltip}
                            >
                              {selectedItem.secondaryLabel ? (
                                <SelectedItemSecondaryLabelTextStyled>
                                  {selectedItem.secondaryLabel}
                                </SelectedItemSecondaryLabelTextStyled>
                              ) : null}
                            </TextTooltip>
                          </>
                        )}
                      </EvenTextOverflow>
                    ) : (
                      <EvenTextOverflow columns={4}>
                        {selectedItem.icon && <>{selectedItem.icon}</>}
                        <TextTooltip
                          title={selectedItem.label}
                          disabled={disableTooltip}
                        >
                          <SelectedItemLabelTextStyled>
                            {selectedItem.label}
                          </SelectedItemLabelTextStyled>
                        </TextTooltip>
                        {selectedItem.secondaryLabel && (
                          <TextTooltip
                            title={selectedItem.secondaryLabel}
                            disabled={disableTooltip}
                          >
                            <SelectedItemSecondaryLabelTextStyled>
                              {selectedItem.secondaryLabel}
                            </SelectedItemSecondaryLabelTextStyled>
                          </TextTooltip>
                        )}
                      </EvenTextOverflow>
                    )}
                  </>
                ) : (
                  <TriggerPlaceholderTextStyled>
                    {triggerPlaceholderText}
                  </TriggerPlaceholderTextStyled>
                )}

                <IconWrapperStyled>
                  <Icon
                    size={16}
                    name='Internal/ChevronUpDown'
                    color={Theme.colorLabelIco}
                  />
                </IconWrapperStyled>
              </DropdownTriggerStyled>
            )
          }
          popoutOptions={{
            visible: isOpen,
            placement: dropdownPlacement,
            offset: dropdownOffset,
          }}
          triggerWrapperStyles={{ width: '100%' }}
          // Using an animated Popout had issues with the combobox functionality
          // which updates the filtered list in real time.
          animation={false}
          fixedPositioning={dropdownUsesFixedPositioning}
          matchTriggerWidth={dropdownMatchesTriggerWidth}
        >
          <DropdownWrapperStyled
            ref={dropdownWrapperRef}
            maxListItems={7}
            inputHeight={typeaheadInputHeight}
            $width={dropdownContainerWidth}
          >
            <TypeaheadWrapperStyled>
              <InputNativeStyled
                {...getInputProps({}, { suppressRefError: true })}
                ref={typeaheadInputRef}
              />
              <IconWrapperStyled>
                <Icon
                  width={16}
                  height={16}
                  name='Search'
                  color={Theme.colorLabelIco}
                />
              </IconWrapperStyled>
            </TypeaheadWrapperStyled>
            <ListWrapperStyled
              {...getMenuProps({}, { suppressRefError: true })}
            >
              {filteredOptions.map((item, index) => {
                return (
                  <ListItemStyled
                    {...getItemProps({ item })}
                    highlighted={highlightedIndex === index}
                    onClick={() => {
                      selectItem(item);
                    }}
                    tabIndex={-1}
                    key={item.value}
                  >
                    {item.icon}
                    <EvenTextOverflow columns={2}>
                      <TextTooltip title={item.label} disabled={disableTooltip}>
                        <OptionLabelTextStyled>
                          {item.label}
                        </OptionLabelTextStyled>
                      </TextTooltip>
                      {item.secondaryLabel && (
                        <TextTooltip
                          title={item.secondaryLabel}
                          disabled={disableTooltip}
                        >
                          <OptionSecondaryLabelTextStyled>
                            {item.secondaryLabel}
                          </OptionSecondaryLabelTextStyled>
                        </TextTooltip>
                      )}
                    </EvenTextOverflow>
                  </ListItemStyled>
                );
              })}
              {filteredOptions.length < 1 && (
                <NoResultsTextStyled>No results</NoResultsTextStyled>
              )}
            </ListWrapperStyled>
          </DropdownWrapperStyled>
        </DropdownPopout>
      </div>
    </RootStyled>
  );
});

export default SelectWithSearch;
