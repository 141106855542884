import { create } from 'zustand';

interface ContactState {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const contactState = create<ContactState>((set) => ({
  open: false,
  setOpen: (open: boolean) => set({ open }),
}));

export const useContactOpenState = () => contactState((state) => state.open);
export const useSetOpenContactDialog = () =>
  contactState((state) => state.setOpen);
