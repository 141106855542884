// https://www.notion.so/primerapi/API-Errors-f65472c118f144aba3ffd64bd10d732c

export type PrimerErrorId =
  | 'ProcessorBadCredentials'
  | 'PaymentMethodConfigAlreadyExists'
  | 'BadlyFormedJSONRequest'
  | 'CannotModifyYourOwnRoles'
  | 'CannotRemoveSelf'
  | 'CouldNotRegisterApplePayMerchant'
  | 'EmailSendingError'
  | 'EntityAlreadyExists'
  | 'EntityNotFound'
  | 'FileSizeExceeded'
  | 'FilterLimitTooLarge'
  | 'InvalidAuthCredentials'
  | 'InvalidCursor'
  | 'InvalidFileType'
  | 'MissingUserRoles'
  | 'MultiFactorAuthenticationAlreadyEnabled'
  | 'MultiFactorAuthenticationInvalidTOTPCode'
  | 'MultiFactorAuthenticationNotEnabled'
  | 'MultiFactorAuthenticationRequired'
  | 'NotSameUserAsInvite'
  | 'OptionsDoNotMatchPaymentType'
  | 'SessionExpired'
  | 'StartAfterEnd'
  | 'StartDateInTheFuture'
  | 'UnexpectedUserType'
  | 'UserAlreadyMember'
  | 'UserInvalidAttributes'
  | 'UserInviteAlreadyAccepted'
  | 'UserInviteAlreadyExists'
  | 'UserInviteCannotInviteSelf'
  | 'UserInviteCodeMismatch'
  | 'UserInviteExpired'
  | 'UserInviteRevoked'
  | 'UserPasswordDoesNotMatch'
  | 'UserPasswordInsufficientComplexity'
  | 'ConfigValidationException'
  | 'RequestValidationError'
  | 'InvalidAmount'
  | 'AllowanceExceeded'
  | 'Unset3dsException'
  | 'UnsetRouteException'
  | string;

export const primerErrorIds: Record<PrimerErrorId, string> = {
  ProcessorBadCredentials: 'The processor credentials are invalid.',
  PaymentMethodConfigAlreadyExists:
    'A configuration already exists for that payment method.',
  BadlyFormedJSONRequest: '',
  CannotModifyYourOwnRoles: '',
  CannotRemoveSelf: '',
  CouldNotRegisterApplePayMerchant: '',
  EmailSendingError: '',
  EntityAlreadyExists: '',
  EntityNotFound: '',
  FileSizeExceeded: '',
  FilterLimitTooLarge: '',
  InvalidAuthCredentials: '',
  InvalidCursor: '',
  InvalidFileType: '',
  MissingUserRoles: '',
  MultiFactorAuthenticationAlreadyEnabled: '',
  MultiFactorAuthenticationInvalidTOTPCode: '',
  MultiFactorAuthenticationNotEnabled: '',
  MultiFactorAuthenticationRequired: '',
  NotSameUserAsInvite: '',
  OptionsDoNotMatchPaymentType: '',
  SessionExpired: '',
  StartAfterEnd: '',
  StartDateInTheFuture: '',
  UnexpectedUserType: '',
  UserAlreadyMember: '',
  UserInvalidAttributes: '',
  UserInviteAlreadyAccepted: '',
  UserInviteAlreadyExists: '',
  UserInviteCannotInviteSelf: '',
  UserInviteCodeMismatch: '',
  UserInviteExpired: '',
  UserInviteRevoked: '',
  UserPasswordDoesNotMatch: '',
  UserPasswordInsufficientComplexity: '',
  Unset3dsException: 'All 3DS outputs require a next step or action.',
  UnsetRouteException: 'All routes require a next step or action.',
};
